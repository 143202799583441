import React from 'react';
import BasePage from '../base';
import { css, StyleSheet } from 'aphrodite';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  convertPxToRem,
  getValueFromObjectByKeys,
  parseSearchUrlToObject,
  translate
} from '../../helper';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import { heightOfHeader } from '../../utils/defined';
import { connect } from 'react-redux';
import withNavigate from '../../component/WithNavigate';
import { Dropdown, Row, Table, Pagination } from 'antd';
import { SVGImageComponentReact } from '../../themes/Images';
import LoginInput from '../../common/login-input';
import { ROUTER_NAME } from '../../router/routers';
import APIManager from '../../connection/ApiManager';
import { quotesStatus } from '../../defined';
import { size } from 'lodash';
import moment from 'moment';
import { UserAction } from '../../redux/action';
import Utils from '../../utils';
import LocalStorage from "../../defined/localStorage";
import { Link } from 'react-router-dom';

const brand = JSON.parse(LocalStorage.getItem('branding'))

const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(30),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    paddingBottom: convertPxToRem(10)
  }
});
const menuKey = {
  legal: 'legal',
  policy: 'policy',
  agreement: 'agreement',
  note: 'note'
};

const ORDER_DELIVERY_STATUS = ['instore','delivery'];

class Quotes extends BasePage {
  constructor(props) {
    super(props);
    this.title = 'Accepted Quotes';
    this.state = {
      menuSelected: menuKey.policy,
      quotes: [],
      next: null,
      previous: null,
      page: parseSearchUrlToObject()?.page ? Number(parseSearchUrlToObject()?.page) : 0,
      total: 0,
      loading: true,
      visibleDropdownSort: false,
      sortBy: parseSearchUrlToObject()?.sortBy || 'desc',
      textSearch: parseSearchUrlToObject()?.search || '',
      value: 0,
      limit: 15,
      page: 1
    };
    this.columns = [
      {
        title: () => (
          <TextBase
            size={convertPxToRem(14)}
            color={Colors.titleTable}
            fontWeight={500}>
            acceptedDate
          </TextBase>
        ),
        dataIndex: 'accepted_date',
        key: 'accepted_date',
        render: (value, record, index) => {
          return moment(record?.updated_at, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm');
        }
      },
      {
        title: () => (
          <TextBase
            size={convertPxToRem(14)}
            color={Colors.titleTable}
            fontWeight={500}>
            customerPo
          </TextBase>
        ),
        dataIndex: 'name',
        key: 'name',
        render: (value, record, index) => {
          return (
            <Link
              style={{
                color: '#517D98',
                textDecoration: 'underline',
                padding: "20px"
              }}
              to={`${ROUTER_NAME.orderDetail}/${record?.order?.id}`}
            >
              {record?.order?.id}
            </Link>
          );
        }
      },
      {
        title: () => (
          <TextBase
            size={convertPxToRem(14)}
            color={Colors.titleTable}
            fontWeight={500}>
            orderName
          </TextBase>
        ),
        dataIndex: 'quote',
        key: 'quote',
        render: (value, record, index) => {
          return record?.order?.name;
        }
      },
      {
        title: () => (
          <TextBase
            size={convertPxToRem(14)}
            color={Colors.titleTable}
            fontWeight={500}>
            fulfilledBy
          </TextBase>
        ),
        dataIndex: 'name',
        key: 'name',
        render: (value, record, index) => {
          return record?.profile?.user?.name||'N/A'
        }
      },
      {
        title: () => (
          <TextBase
            size={convertPxToRem(14)}
            color={Colors.titleTable}
            fontWeight={500}
          >
            paymentInfo
          </TextBase>
        ),
        dataIndex: 'stripe_charge_id',
        key: 'stripe_charge_id',
        render: (value, record, index) => {
          return record?.stripe_charge_id == "NS" ? "Account Billing"
              : "Stripe";
        }
      },
      {
        title: () => (
          <TextBase
            size={convertPxToRem(14)}
            color={Colors.titleTable}
            fontWeight={500}>
            fulfillmentDate
          </TextBase>
        ),
        key: 'order_fullfill_date',
        dataIndex: 'order_fullfill_date',
        render: (value, record, index) => {
          return moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm');
        }
      },
    ];
    this.limit = 50;
  }

  componentDidMount() {
    super.componentDidMount();
    this.getUpdates();
    this.getData();
  }

  onSearch = () => {
    this.getData(0);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.vendor?.id !== this.props?.vendor?.id) {
      this.getData(0, nextProps);
    }
  }

  getData = async (page = this.state.page, props = this.props, value = this.state.value) => {
    try {
      const { sortBy, textSearch } = this.state;
      const queryPage = parseSearchUrlToObject();
      const { userInfo } = props;
      const { user } = userInfo;
      if (!user?.vendor?.id) {
        this.setState({
          loading: false
        });
        return;
      }
      if (textSearch) {
        queryPage.search = textSearch;
      }
      this.setState({ loading: true });
      let query = {
        offset: this.state.limit * (page-1),
        limit: this.state.limit,
        vendor__id: user?.vendor?.id,
        status: quotesStatus.ACCEPTED,
        ordering: sortBy === undefined ? undefined : sortBy === 'asc' ? `created_at` : '-created_at'
      };

      if(value !== 0 && value <= ORDER_DELIVERY_STATUS.length){
        query.order_delivery_status = ORDER_DELIVERY_STATUS[value - 1];
      }

      if (textSearch) {
        query.search = textSearch;
      }
      const resOrder = await APIManager.getInstance().getQuotes(query);
      this.setState({
        quotes: resOrder?.data?.results || [],
        total: resOrder?.data?.count,
        next: resOrder?.data?.next,
        previous: resOrder?.data?.previous,
        page: page||1,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  nextData = () => {
    const { next } = this.state;
    if (!next) return;
    this.getData(this.state.page + 1);
  };
  previousData = () => {
    const { previous } = this.state;
    if (!previous) return;
    this.getData(this.state.page - 1);
  };
  onClickItemQuote = async (record) => {
    try {
      const quoteId = record?.id;
      const resUserQuote = await APIManager.getInstance().getUserQuotes({
        quote__id: quoteId
      });
      const results = resUserQuote?.data?.results;
      if (results && size(results)) {
        const itemFirst = results[0];
        if (itemFirst?.user) {
          const resOrder = await APIManager.getInstance().getOrders({
            vendors__id: record?.vendor?.id,
            user__id: itemFirst?.user
          });
          const resultsOrder = resOrder?.data?.results;
          const orderWithQuote = resultsOrder.filter((x) =>
            x?.quotes.find((y) => `${y?.id}` === `${quoteId}`)
          );
          if (orderWithQuote && size(orderWithQuote)) {
            this.props.navigate(`${ROUTER_NAME.orderDetail}?id=${orderWithQuote[0]?.id}`);
          }
        }
      }
    } catch (error) { }
  };

  getUpdates = () => {
    const { userInfo } = this.props;

    this.socket = new ReconnectingWebSocket(
      `wss://${Utils.constants.API_URL?.replace('https://', '')}ws/orders/?token=${userInfo?.token
      }`
    );

    this.socket.onmessage = (event) => {
      if(window.location.pathname.includes('quotes')){
        const data = JSON.parse(event.data);
        this.getData(0);
        this.props.getNotifications();
      }
    };
  };

  tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  handleChange = async (event,newValue) => {
    this.setState({ value: newValue });
    await this.getData(this.state.page, this.props, newValue);
  };

  renderContent() {
    const {
      textSearch,
      quotes,
      loading,
      visibleDropdownSort,
      sortBy,
      value,
    } = this.state;
    const tabs = [
      { label: "all", index: 0 },
      { label: "inStorePickup", index: 1 },
      { label: "delivery", index: 2 },
    ];
    return (
      <div style={{ backgroundColor: "#fff" }} className={css(styles.container)}>
        <div className="flex-row-center">
          <TextBase
            style={{ marginBottom: 20 }}
            fontWeight={600}
            size={convertPxToRem(24)}
            color={Colors.titlePage}>
            acceptedQuotes
          </TextBase>
        </div>
        <Tabs
          style={{ marginBottom: '20px' }}
          TabIndicatorProps={{ style: { background: '#F8B218' }}}
          value={value}
          onChange={this.handleChange}
          aria-label="tabs"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.index}
              TabIndicatorProps={{ style: { background: '#F8B218' }}}
              style={{
                borderBottom: value === tab.index ? "1px solid #F8B218" : "1px solid #C8C8C8",
                color: value === tab.index ? "#F8B218" : "#C8C8C8",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: '21px',
                textTransform: "capitalize",
              }}
              label={translate(tab.label)}
              {...this.tabProps(tab.index)}
            />
          ))}
        </Tabs>
        <Row style={{padding: "0 20px"}}>
          <div className="flex-row-center-between width-100">
            <div>
              <div>
                <LoginInput
                  value={textSearch}
                  onChange={(text) => {
                    this.changingText = true;
                    if (this.timeoutChangeTextSearch) {
                      clearTimeout(this.timeoutChangeTextSearch);
                      this.timeoutChangeTextSearch = null;
                    }
                    this.setState({
                      textSearch: text
                    });
                    this.timeoutChangeTextSearch = setTimeout(() => {
                      this.changingText = false;
                      this.onSearch();
                    }, 500);
                  }}
                  style={{
                    fontSize: convertPxToRem(16),
                    color: Colors.grey,
                    width: 350
                  }}
                  className="search"
                  placeholder="searchQuotes"
                  rightElement={<SVGImageComponentReact.IconSearch className="search-icon" />}
                />
              </div>
            </div>
            <div className="flex-row-center">
              <Dropdown
                trigger={['click']}
                overlay={
                  <div
                    className="bg-white"
                    style={{
                      borderRadius: convertPxToRem(5),
                      paddingInline: convertPxToRem(10)
                    }}>
                    <TextBase
                      style={{
                        paddingTop: convertPxToRem(15),
                        paddingBottom: convertPxToRem(8),
                        textTransform: "uppercase",
                      }}
                      className="cursor-pointer"
                      size={14}
                      color={sortBy === 'desc' ? Colors.mainColor : Colors.black}
                      onClick={() => {
                        this.setState(
                          {
                            sortBy: 'desc',
                            visibleDropdownSort: false
                          },
                          () => {
                            this.getData(0);
                          }
                        );
                      }}>
                      newestToOldest
                    </TextBase>
                    <TextBase
                      style={{
                        paddingTop: convertPxToRem(8),
                        paddingBottom: convertPxToRem(15),
                        textTransform: "uppercase",
                      }}
                      className="cursor-pointer"
                      size={14}
                      color={sortBy === 'asc' ? Colors.mainColor : Colors.black}
                      onClick={() => {
                        this.setState(
                          {
                            sortBy: 'asc',
                            visibleDropdownSort: false
                          },
                          () => {
                            this.getData(0);
                          }
                        );
                      }}>
                      oldestToNewest
                    </TextBase>
                  </div>
                }
                visible={visibleDropdownSort}>
                <div
                  className="flex-row-center-between filter-box cursor-pointer"
                  onClick={() =>
                    this.setState({
                      visibleDropdownSort: !visibleDropdownSort,
                      visibleDropdownFilter: false
                    })
                  }>
                  <TextBase
                    style={{
                      marginRight: convertPxToRem(13),
                      padding: 14
                    }}
                    size={convertPxToRem(14)}
                    color={Colors.grey}
                    fontWeight={400}>
                    sortBy{' '}
                    <Link
                      style={{
                        color: 'black',
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "uppercase",
                      }}>
                      {sortBy === 'desc' ? translate('newestToOldest') : translate('oldestToNewest')}
                    </Link>
                  </TextBase>
                  <div className="filter-divider"></div>
                  <div style={{ padding: 12 }}>
                    <SVGImageComponentReact.IconArrowDown />
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </Row>
        <Row style={{ marginTop: convertPxToRem(38), padding: "0 20px" }}>
          <Table
            style={{ width: '100%' }}
            columns={this.columns}
            dataSource={quotes}
            total={this.state.total}
            pagination={false}
            components={{
              header: {
                cell: ({ children, ...rest }) => {
                  return (
                    <th
                      {...rest}
                      style={{
                        padding: '23px',
                      }}
                    >
                      {children}
                    </th>
                  );
                }
              },
              body: {
                cell: ({ children, ...rest }) => {
                  return (
                    <td
                      {...rest}
                      style={{
                        padding: '23px',
                      }}>
                      {children}
                    </td>
                  );
                },
              }
            }}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  this.props.navigate(`${ROUTER_NAME.orderDetail}/${record?.order?.id}`)
                }
              };
            }}
            loading={loading}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              width: "100%"
            }}
          >
            <TextBase
              style={{
                color: '#000'
              }}
            >
              {translate('showing')} <b>{(this.state.page - 1) * this.state.limit + 1}</b> {translate('to')} <b>{Math.min(this.state.page * this.state.limit, this.state.total)}</b> {translate('of')} <b>{this.state.total}</b> {translate('results')}
            </TextBase>
            <Pagination
              defaultCurrent={this.state.page}
              defaultPageSize={this.state.limit}
              total={this.state.total}
              onChange={pageNumber=>this.setState({page:pageNumber},()=>this.getData(pageNumber))}
              showSizeChanger={false}
            />
          </div>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    listNotification: getValueFromObjectByKeys(state, ['User', 'notification']),
    vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    whiteLabel: getValueFromObjectByKeys(state, ['User', 'branding']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: () => {
      return dispatch(UserAction.getNotifications());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Quotes));
