import React from 'react';
import moment from 'moment/moment';
import { createQuoteApiCall, updateQuoteApiCall } from './Action';
import Modal from '../../common/model';
import { quotesSchema } from '../../schema/vendorSchema'
import { Formik, Form as FormikForm } from 'formik';
import { CircularProgress, DialogActions, Grid, InputAdornment, styled } from '@mui/material';
import { Label } from '../../common/form/TextInput';
import { translate } from '../../helper';
import { TextField } from '../../common/form/input';
import { DatePicker } from 'antd';
import en from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { FormActionButton } from './components';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_SERVER } from './helper';

const SendQuoteWrapper = styled('div')(({ theme }) => ({
    '& .MuiTextField-root': {
        'width': '100%'
    },
    '& .MuiInputBase-input': {
        'padding': '8px 10px'
    }
}));

const QuotesModal = ({ isOpen, orderDetail, refetch, user, onClickClose, vendorId }) => {
    const quoteItem = orderDetail?.quotes?.find((x) => x.vendor?.id === vendorId);

    const handleSubmit = (values, actions) => {
        delete values.order_name;
        values.order_fullfill_date = values.order_fullfill_date ? moment(values.order_fullfill_date, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT_SERVER) : null;

        if (quoteItem) {
            updateQuoteApiCall(quoteItem.id, values, actions.setSubmitting, refetch);
        } else {
            values.status = 'SENT';
            createQuoteApiCall(values, orderDetail, actions.setSubmitting, refetch);
        }
    };
    const buddhistLocale = {
        ...en,
        lang: {
          ...en.lang,
          fieldDateFormat: 'YYYY-MM-DD',
          fieldDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
          yearFormat: 'YYYY',
          cellYearFormat: 'YYYY',
        },

      };
    return (

        <Modal
            title={'quote'}
            isOpen={isOpen}
            onClose={onClickClose}
        >
            <SendQuoteWrapper>
                <Formik
                    initialValues={{
                        order_name: orderDetail?.name || '',
                        order_fullfill_date: quoteItem?.order_fullfill_date ? moment(quoteItem?.order_fullfill_date, DATE_TIME_FORMAT_SERVER).format(DATE_TIME_FORMAT) : '',
                        description: quoteItem?.description || '',
                        vendor: vendorId,
                        quote: quoteItem?.quote || ''
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={quotesSchema}
                >
                    {({ isSubmitting, values, setFieldValue, errors, touched }) => (

                        <FormikForm>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Label>{translate('orderName')}</Label>
                                </Grid>
                                <Grid style={{ marginBottom: '2rem', height: '60px' }} item xs={12}>
                                    <TextField
                                        value={values.order_name}
                                        type="text"
                                        id="order_name"
                                        name="order_name"
                                        fullWidth
                                        disabled
                                        style={{
                                            height: '40px',
                                            border: '1px solid #ddd',
                                            width: '100%',
                                            backgroundColor: '#F8F8F8',
                                            borderRadius: "8px"
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Label>{translate('orderFulfillDate')}</Label>
                                </Grid>

                                <Grid style={{ marginBottom: '1.5rem' }} item xs={12}>
                                    <DatePicker
                                        style={{
                                            width: '100%',
                                            borderRadius: '8px',
                                            padding: '7px 10px',
                                            height: '55px',
                                            border: touched.order_fullfill_date && errors.order_fullfill_date ? '1px solid #ff0000' : '1px solid #ddd',
                                            backgroundColor: '#F8F8F8'
                                        }}
                                        showTime={{ format: 'hh:mm A', use12Hours: true }}
                                        locale={buddhistLocale}
                                        onChange={(value, dateString) => {
                                            setFieldValue('order_fullfill_date', value ? dayjs(value).format(DATE_TIME_FORMAT) : '');
                                        }}
                                        helperText={touched.order_fullfill_date && errors.order_fullfill_date}
                                        disabledDate={(current) => {
                                            // Can not select days before today and today
                                            return current && current < moment().subtract(1, 'day');
                                        }}
                                        value={values.order_fullfill_date ? dayjs(values.order_fullfill_date, DATE_TIME_FORMAT) : undefined}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Label>{translate('orderPrice')}</Label>
                                </Grid>
                                <Grid style={{ marginBottom: '1.8rem', height: '60px' }} item xs={12}>
                                    <TextField
                                        value={values.quote}
                                        type="text"
                                        pattern="[0-9]"
                                        id="quote"
                                        name="quote"
                                        error={Boolean(touched.quote && errors.quote)}
                                        helperText={touched.quote && errors.quote}
                                        fullWidth
                                        InputProps={{
                                            ...(values.quote && {
                                                startAdornment: (
                                                    <InputAdornment sx={{ marginLeft: '10px' }}
                                                                    position="start">USD</InputAdornment>
                                                )
                                            })
                                        }}
                                        style={{
                                            height: '40px',
                                            border: '1px solid #ddd',
                                            width: '100%',
                                            backgroundColor: '#F8F8F8',
                                            appearance: 'none',
                                            borderRadius: "8px"
                                        }}
                                        onChange={({ target }) => {
                                            setFieldValue('quote', !isNaN(target?.value) ? target?.value : values.quote);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Label>{translate('quoteInformation')}</Label>
                                </Grid>
                                <Grid style={{ marginBottom: '1.5rem' }} item xs={12}>
                                    <TextField
                                        value={values.description}
                                        type="text"
                                        id="description"
                                        name="description"
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        fullWidth
                                        asTextArea
                                        minRows={8}
                                        style={{
                                            backgroundColor: '#F8F8F8'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12}>
                                <DialogActions>
                                    <FormActionButton
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : (
                                            quoteItem ? translate('updateQuote') : translate('sendQuote')
                                        )}
                                    </FormActionButton>
                                </DialogActions>
                            </Grid>
                        </FormikForm>
                    )}
                </Formik>
            </SendQuoteWrapper>
        </Modal>
    );

};

export default QuotesModal;
