const encodeString = (str, isRequire = false) => {
  if (isRequire) {
    return str;
  }
  return str;
};

export const REQUEST_TYPE = {
  WHITE_LABEL: 'WHITE_LABEL',
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  GET_PROFILE: 'GET_PROFILE',
  GET_VENDOR_PROFILE: 'GET_VENDOR_PROFILE',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  GET_VENDOR: 'GET_VENDOR',
  UPDATE_FINANCIAL: 'UPDATE_FINANCIAL',
  UPDATE_USER_MODEL: 'UPDATE_USER_MODEL',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SEND_SMS: 'SEND_SMS',
  SEND_SMS_USER: 'SEND_SMS_USER',
  VERIFY_PHONE_NUMBER: 'VERIFY_PHONE_NUMBER',
  VERIFY_PHONE_NUMBER_USER: 'VERIFY_PHONE_NUMBER_USER',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_APPEND: 'GET_NOTIFICATIONS_APPEND',
  GET_NOTIFICATIONS_COUNT: 'GET_NOTIFICATIONS_COUNT',
  UPDATE_USER_NOTIFICATIONS_COUNT: 'UPDATE_USER_NOTIFICATIONS_COUNT',
  UPDATE_NOTIFICATIONS_COUNT: 'UPDATE_NOTIFICATIONS_COUNT',
  REGISTER_DEVICE: 'REGISTER_DEVICE',
  MARK_AS_READ: 'MARK_AS_READ',
  MARK_AS_READ_ALL: 'MARK_AS_READ_ALL',
  MARK_AS_READ_ALL_VENDORNOTES: 'MARK_AS_READ_ALL_VENDORNOTES',
  GET_DASHBOARD_TOTALS: 'GET_DASHBOARD_TOTALS',
  GET_ACCEPTED_ORDERS: 'GET_ACCEPTED_ORDERS',
  GET_NUMBER_OF_QUOTES: 'GET_NUMBER_OF_QUOTES',
  INVITE_TEAM_MEMBER: 'INVITE_TEAM_MEMBER',
  GET_TEAM_MEMBERS: 'GET_TEAM_MEMBERS',
  GET_TEAM_PROFILE: 'GET_TEAM_PROFILE',
  SAVE_BRANDING: 'SAVE_BRANDING',
  SAVE_ORDER: 'SAVE_ORDER',
  SAVE_ORDER_BRAND: 'SAVE_ORDER_BRAND',
  LOG_OUT: 'LOG_OUT',
  //assets
  //investment
  ASSET_INVESTMENT_GET: 'ASSET_INVESTMENT_GET',
  ASSET_INVESTMENT_POST: 'ASSET_INVESTMENT_POST',
  ASSET_INVESTMENT_PATCH: 'ASSET_INVESTMENT_PATCH',
  ASSET_INVESTMENT_PUT: 'ASSET_INVESTMENT_PUT',
  ASSET_INVESTMENT_DELETE: 'ASSET_INVESTMENT_DELETE',
  //investment
  ASSET_INSURANCE_GET: 'ASSET_INSURANCE_GET',
  ASSET_INSURANCE_POST: 'ASSET_INSURANCE_POST',
  ASSET_INSURANCE_PATCH: 'ASSET_INSURANCE_PATCH',
  ASSET_INSURANCE_PUT: 'ASSET_INSURANCE_PUT',
  ASSET_INSURANCE_DELETE: 'ASSET_INSURANCE_DELETE',
  //real-estate
  ASSET_REAL_ESTATES_GET: 'ASSET_REAL_ESTATES_GET',
  ASSET_REAL_ESTATES_POST: 'ASSET_REAL_ESTATES_POST',
  ASSET_REAL_ESTATES_PATCH: 'ASSET_REAL_ESTATES_PATCH',
  ASSET_REAL_ESTATES_PUT: 'ASSET_REAL_ESTATES_PUT',
  ASSET_REAL_ESTATES_DELETE: 'ASSET_REAL_ESTATES_DELETE',
  //liabilities
  //real-estate
  LIABILITIES_REAL_ESTATES_GET: 'LIABILITIES_REAL_ESTATES_GET',
  LIABILITIES_REAL_ESTATES_POST: 'LIABILITIES_REAL_ESTATES_POST',
  LIABILITIES_REAL_ESTATES_PATCH: 'LIABILITIES_REAL_ESTATES_PATCH',
  LIABILITIES_REAL_ESTATES_PUT: 'LIABILITIES_REAL_ESTATES_PUT',
  LIABILITIES_REAL_ESTATES_DELETE: 'LIABILITIES_REAL_ESTATES_DELETE',
  //credit-card
  LIABILITIES_CREDIT_CARDS_GET: 'LIABILITIES_CREDIT_CARDS_GET',
  LIABILITIES_CREDIT_CARDS_POST: 'LIABILITIES_CREDIT_CARDS_POST',
  LIABILITIES_CREDIT_CARDS_PATCH: 'LIABILITIES_CREDIT_CARDS_PATCH',
  LIABILITIES_CREDIT_CARDS_PUT: 'LIABILITIES_CREDIT_CARDS_PUT',
  LIABILITIES_CREDIT_CARDS_DELETE: 'LIABILITIES_CREDIT_CARDS_DELETE',
  //personal-note
  LIABILITIES_PERSONAL_NOTE_GET: 'LIABILITIES_PERSONAL_NOTE_GET',
  LIABILITIES_PERSONAL_NOTE_POST: 'LIABILITIES_PERSONAL_NOTE_POST',
  LIABILITIES_PERSONAL_NOTE_PATCH: 'LIABILITIES_PERSONAL_NOTE_PATCH',
  LIABILITIES_PERSONAL_NOTE_PUT: 'LIABILITIES_PERSONAL_NOTE_PUT',
  LIABILITIES_PERSONAL_NOTE_DELETE: 'LIABILITIES_PERSONAL_NOTE_DELETE',
  //loans
  LIABILITIES_LOANS_GET: 'LIABILITIES_LOANS_GET',
  LIABILITIES_LOANS_POST: 'LIABILITIES_LOANS_POST',
  LIABILITIES_LOANS_PATCH: 'LIABILITIES_LOANS_PATCH',
  LIABILITIES_LOANS_PUT: 'LIABILITIES_LOANS_PUT',
  LIABILITIES_LOANS_DELETE: 'LIABILITIES_LOANS_DELETE',
  //others
  LIABILITIES_OTHERS_GET: 'LIABILITIES_OTHERS_GET',
  LIABILITIES_OTHERS_POST: 'LIABILITIES_OTHERS_POST',
  LIABILITIES_OTHERS_PATCH: 'LIABILITIES_OTHERS_PATCH',
  LIABILITIES_OTHERS_PUT: 'LIABILITIES_OTHERS_PUT',
  LIABILITIES_OTHERS_DELETE: 'LIABILITIES_OTHERS_DELETE',
  //income & express
  //pay-stubs
  INCOME_EXPRESS_PAY_STUBS_GET: 'INCOME_EXPRESS_PAY_STUBS_GET',
  INCOME_EXPRESS_PAY_STUBS_POST: 'INCOME_EXPRESS_PAY_STUBS_POST',
  INCOME_EXPRESS_PAY_STUBS_PATCH: 'INCOME_EXPRESS_PAY_STUBS_PATCH',
  INCOME_EXPRESS_PAY_STUBS_PUT: 'INCOME_EXPRESS_PAY_STUBS_PUT',
  INCOME_EXPRESS_PAY_STUBS_DELETE: 'INCOME_EXPRESS_PAY_STUBS_DELETE',
  //
  INCOME_EXPRESS_TAX_RETURN_GET: 'INCOME_EXPRESS_TAX_RETURN_GET',
  INCOME_EXPRESS_TAX_RETURN_POST: 'INCOME_EXPRESS_TAX_RETURN_POST',
  INCOME_EXPRESS_TAX_RETURN_PATCH: 'INCOME_EXPRESS_TAX_RETURN_PATCH',
  INCOME_EXPRESS_TAX_RETURN_PUT: 'INCOME_EXPRESS_TAX_RETURN_PUT',
  INCOME_EXPRESS_TAX_RETURN_DELETE: 'INCOME_EXPRESS_TAX_RETURN_DELETE',
  //
  INCOME_EXPRESS_EXPENSES_GET: 'INCOME_EXPRESS_EXPENSES_GET',
  INCOME_EXPRESS_EXPENSES_POST: 'INCOME_EXPRESS_EXPENSES_POST',
  INCOME_EXPRESS_EXPENSES_PATCH: 'INCOME_EXPRESS_EXPENSES_PATCH',
  INCOME_EXPRESS_EXPENSES_PUT: 'INCOME_EXPRESS_EXPENSES_PUT',
  INCOME_EXPRESS_EXPENSES_DELETE: 'INCOME_EXPRESS_EXPENSES_DELETE',
  //
  CREATE_VENDOR: 'CREATE_VENDOR',
  //
  GET_ORDER: 'GET_ORDER',
  GET_SEARCH_ANALYTICS : 'GET_SEARCH_ANALYTICS',
  CARDS_DATA : 'CARDS_DATA'
};

export const REQUEST_SUBTYPE = {
  REQUEST: 'OnRequest',
  ERROR: 'OnError',
  SUCCESS: 'OnSuccess'
};

export const NORMAL_TYPE = {
  CHANGE_RESIZE_WINDOW: 'CHANGE_RESIZE_WINDOW',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  REFRESH_TOKEN_FAILED: 'REFRESH_TOKEN_FAILED',
  SAVE_USER_DETAIL_LOCAL: 'SAVE_USER_DETAIL_LOCAL',
  SAVE_USER_INFO: 'SAVE_USER_INFO',
  SAVE_USER_CACHE: 'SAVE_USER_CACHE',
  SAVE_NOTIFICATION: 'SAVE_NOTIFICATION',
  SAVE_BRANDING: 'SAVE_BRANDING',
  SAVE_ORDER: 'SAVE_ORDER',
  SAVE_ORDER_BRAND: 'SAVE_ORDER_BRAND',
  LOG_OUT: 'LOG_OUT'
};

const ActionTypes = {
  REQUEST_TYPE,
  REQUEST_SUBTYPE,
  NORMAL_TYPE
};

export default ActionTypes;
