import { useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from "react-i18next"
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { css, StyleSheet } from 'aphrodite';
import {TableBody, TableCell, TableRow, styled, Box, Typography, Switch, Container } from '@mui/material';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsIcon from '@mui/icons-material/Notifications';

import ButtonGradient from '../../common/button-gradient';
import { SVGImageComponentReact } from '../../themes/Images';
import { useSelector } from 'react-redux';
import "./modal.style.css"
import { Table } from 'antd'
import { ReactComponent as NoRecordIcon } from '../../assets/image/svg/no_record.svg';
import { ContainedButton } from '../../../src/mobile/common/inputs/Button';
import { translate } from '../../helper';
import poweredBy from '../../assets/image/png/PoweredByMove_logo-100.jpg';

const StyledContainer = styled(Container)(({ theme }) => ({
    '& .slick-dots': {
        bottom: 'revert-layer'
    },
}));

const NotFoundWrapper = styled('div')(({ }) => ({
    'textAlign': 'center',
    'height': '150px',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    '& svg': {
        width: '60px',
        height: '60px',
        '& img': {
            widht: '100%',
            height: '100%'
        }
    }
}));

const styles = StyleSheet.create({
    iconNotification: {
      width: "16px",
      height: "10px",
      marginRight: "10px",
      cursor: 'pointer'
    },
    iconNoNotification: {
      width: "12px",
      height: "12px",
      marginRight: "5px",
      cursor: 'pointer'
    },
    iconUser: {
      width: "1.5rem",
      height: "1.5rem",
      borderRadius: "15px",
      marginLeft: "10px",
      marginRight: "10px",
      border: `solid 2px #F2F2F2`
    }
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: "2rem",
    height: "1rem",
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: "0.1rem",
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#ddd',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: "0.8rem",
      height: "0.8rem",
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));

const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

function PreviewModal({ isOpen, onClose, type, webTheme, mobTheme, orderForm, orderBackgroundColor, emailData, onSubmit, loading }) {
    const { t } = useTranslation()
    const { userInfo } = useSelector(state=>state?.User)
    const whiteLabel = useSelector((state) => state.User?.branding);
    const sliderRef = useRef();
    const [showWelcome, setShowWelcome] = useState(false);
    const [isLastScreen, setIsLastScreen] = useState(false);

    const handleBeforeChange = (oldIndex) => {
        
        if (oldIndex == 1) {
            setIsLastScreen(true)
        } else {
            setIsLastScreen(false);
        }
    }
    
    let height = window.innerHeight;
    let width = window.innerWidth;
    const { 
        sideBannerColor,
        topBannerColor,
        backgroundColor,
        buttonColor,
        logo
    } = webTheme

    const {
        screenNames,
        screenFiles,
        mobileTopBannerColor: mobTopBannerColor,
        mobileBackgroundColor: mobBackgroundColor,
        mobileButtonColor: mobButtonColor,
        mobileLogo: mobLogo
    } = mobTheme

    const { 
        emailBackgroundColor,
        emailLogo,
        emailTemplate
    } = emailData
    function imageUrl(url) {
        if (url?.type) {
            return URL.createObjectURL(url)
        }
        return url
    }

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div
                style={{
                    width: width,
                    height: height,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: width*0.5,
                        height: height*0.8,
                        backgroundColor: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        padding: "20px 40px",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "90%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {type==="web"&&<div
                            style={{
                                width: "100%",
                                height: `${(height*0.4)-40}px`,
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid #ddd"
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "9%",
                                    backgroundColor: `rgba(${topBannerColor?.r}, ${topBannerColor?.g}, ${topBannerColor?.b}, ${topBannerColor?.a})`,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <MenuFoldOutlined 
                                        style={{
                                            fontSize: "0.5rem",
                                            color: "#fff",
                                            marginLeft: "10px",
                                            marginRight: "15px"
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontWeight: "600",
                                            color: "#fff",
                                            fontSize: "0.5rem",
                                        }}
                                    >
                                        {userInfo?.user?.vendor?.business_name || 'N/A'}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <SVGImageComponentReact.IconNotes style={{width: "15px", marginRight: "5px"}} />
                                    <SVGImageComponentReact.NoNotificationsIcon style={{width: "15px", marginRight: "5px", fontSize: "0.5rem",}} />
                                    <span style={{fontSize: "0.5rem", color: "#fff", paddingRight: "5px", borderRight: "1px solid #000"}}>{t('Notifications')}</span>
                                    <div className="layout-username" style={{display:"flex"}}>
                                        <div className="flex-column">
                                            <span className="header-user-name" style={{fontSize: "0.5rem",}}>
                                                {
                                                    userInfo?.user?.first_name ? userInfo?.user?.first_name + (' ' + userInfo?.user?.last_name || ''): userInfo?.user?.name
                                                }
                                            </span>
                                            <span className="header-user-email" style={{fontSize: "0.5rem",}}>
                                                {userInfo?.user?.email || 'Email'}
                                            </span>
                                        </div>
                                        {userInfo?.profile?.profile_image ? (
                                            <img
                                                src={userInfo?.profile?.profile_image}
                                                className={css(styles.iconUser)}
                                                alt="avatar"
                                            />
                                        ) : (
                                            <SVGImageComponentReact.IconUser className={css(styles.iconUser)} />
                                        )}
                                        <SVGImageComponentReact.IconDropDown
                                            style={{ marginRight: "15px", fontSize: "0.5rem", }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    height: "90%",
                                    backgroundColor: `#fff`,
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div
                                    style={{
                                        width: "15%",
                                        height: "100%",
                                        
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div style={{backgroundColor: "#fff", height: "12%", padding: "5px"}} className='logo'>
                                        <img style={{height: "100%", width: "unset"}} src={imageUrl(logo)} alt='logo'/>
                                    </div>
                                    <ul
                                        style={{
                                            paddingLeft: "10px",
                                            height: "73%",
                                            backgroundColor: `rgba(${sideBannerColor?.r}, ${sideBannerColor?.g}, ${sideBannerColor?.b}, ${sideBannerColor?.a})`,
                                            borderTopRightRadius: "5px",
                                            marginBottom: "0",
                                            borderBottomRightRadius: "5px",
                                        }}
                                    >
                                        <li
                                            style={{
                                                display: "flex",
                                                listStyleType: "none",
                                                alignItems: 'center',
                                                paddingLeft: "10px",
                                                marginBottom: "10px",
                                                fontSize: "0.5rem",
                                            }}
                                        >
                                            <SVGImageComponentReact.IconMenuOrder style={{width: "15px"}} />
                                            <span style={{color: "#fff", fontWeight: "500", marginLeft: "5px"}} className="nav-text">{t('orders')}</span>
                                        </li>
                                        <li
                                            style={{
                                                display: "flex",
                                                listStyleType: "none",
                                                alignItems: 'center',
                                                paddingLeft: "10px",
                                                marginBottom: "10px",
                                                borderRight: "3px solid #fbb516",
                                                fontSize: "0.5rem",
                                            }}
                                        >
                                            <SVGImageComponentReact.IconNotesActive style={{width: "15px"}} />
                                            <span style={{color: "#fbb516", fontWeight: "500", marginLeft: "5px"}} className="nav-text">{t('vendorNotes')}</span>
                                        </li>
                                        <li
                                            style={{
                                                display: "flex",
                                                listStyleType: "none",
                                                alignItems: 'center',
                                                paddingLeft: "10px",
                                                marginBottom: "10px",
                                                fontSize: "0.5rem",
                                            }}
                                        >
                                            <SVGImageComponentReact.IconChart style={{width: "15px"}} />
                                            <span style={{color: "#fff", fontWeight: "500", marginLeft: "5px"}} className="nav-text">{t('analytics')}</span>
                                        </li>
                                        <li
                                            style={{
                                                display: "flex",
                                                listStyleType: "none",
                                                alignItems: 'center',
                                                paddingLeft: "10px",
                                                marginBottom: "10px",
                                                fontSize: "0.5rem",
                                            }}
                                        >
                                            <SVGImageComponentReact.IconQuotes style={{width: "15px"}} />
                                            <span style={{color: "#fff", fontWeight: "500", marginLeft: "5px"}} className="nav-text">{t('acceptedQuotes')}</span>
                                        </li>
                                    </ul>
                                    <div style={{backgroundColor: "#fff", height: "15%"}} className='logo'>
                                        <img src={imageUrl(poweredBy)} style={{height: "100%", width: "unset"}} alt='logo'/>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "85%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "90%",
                                            backgroundColor: `#fff`,
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "20px"
                                        }}
                                    >
                                        <span style={{fontWeight: "500", marginBottom: "10px", fontSize: "0.8rem",}}>
                                            {t('vendorNotes')}
                                        </span>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <input 
                                                style={{
                                                    height: "30px",
                                                    borderRadius: "2px !important",
                                                    width: "150px",
                                                    boxShadow: "none",
                                                    border: "none",
                                                    fontSize: "2px !important",
                                                    paddingLeft: "10px",
                                                    fontSize: "0.5rem",
                                                    border: "1px solid #ddd"
                                                }}
                                                id="input"
                                                placeholder={t('searchVendorNotes')}
                                            />
                                            <ButtonGradient 
                                                title={t('create')} 
                                                backgroundColor={`rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`}
                                                style={{
                                                    background: `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`,
                                                    width: "25%",
                                                    maxWidth: "150px",
                                                    height: "30px",
                                                    fontSize: "0.5rem",
                                                }}
                                                textStyles={{
                                                    fontSize: "0.5rem",
                                                    textTransform: "uppercase"
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <Table
                                                columns={[
                                                    {
                                                        id: 'customer_po',
                                                        title: t('customerPo'),
                                                        minWidth: 100,
                                                        size: "0.5rem",
                                                    },
                                                    {
                                                        id: 'order_name',
                                                        title: t('orderName'),
                                                        minWidth: 170,
                                                        size: "0.5rem",
                                                    },
                                                    {
                                                        id: 'timestamp',
                                                        title: t('dateCreated'),
                                                        minWidth: 100,
                                                        size: "0.5rem",
                                                    },
                                                    {
                                                        id: 'note_number',
                                                        title: t('vendorNoteNumber'),
                                                        minWidth: 170,
                                                        size: "0.5rem",
                                                    },
                                                    {
                                                        id: 'action',
                                                        title: '',
                                                        size: "0.5rem",
                                                        minWidth: 100,
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>}
                        {type==="mob"&&<div
                            style={{
                                width: "50%",
                                maxWidth: "350px",
                                height: `100%`,
                                display: "flex",
                                flexDirection: "column",
                                
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    display: "flex",
                                    height: "90%",
                                    flexDirection: "column",
                                    
                                    borderRadius: "5px"
                                }}
                            >
                                {
                                    !showWelcome ? <div
                                        style={{
                                            border: "1px solid #ddd",
                                            width: "100%",
                                            display: "flex",
                                            height: "90%",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                                            <img style={{ width: "2.5rem", height: "2.5rem" }} src={imageUrl(mobLogo)} />
                                        </div>
                                        <StyledContainer style={{minHeight:'30rem'}}>
                                            <div style={{ marginTop: '20px', maxHeight: "11rem" }}>
                                                <Slider  {...settings} style={{ maxHeight: "10rem" }} beforeChange={handleBeforeChange} ref={sliderRef}>
                                                    <div style={{height: "15rem"}}>
                                                        <div style={{height: "15rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                            <img style={{ width: '100%', height: "100%" }} src={imageUrl(screenFiles?.screen1)} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <h6 style={{fontSize: "12px", marginTop: "20px", wordBreak: "break-word"}}>{screenNames?.screen1}</h6>
                                                        </div>
                                                    </div>
                                                    <div style={{height: "15rem"}}>
                                                        <div style={{height: "15rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                            <img style={{ width: '100%', height: "100%" }} src={imageUrl(screenFiles?.screen2)} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <h6 style={{fontSize: "12px", marginTop: "20px", wordBreak: "break-word"}}>{screenNames?.screen2}</h6>
                                                        </div>
                                                    </div>
                                                    <div style={{height: "15rem"}}>
                                                        <div style={{height: "15rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                            <img style={{ width: '100%', height: "100%" }} src={imageUrl(screenFiles?.screen3)} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <h6 style={{fontSize: "12px", marginTop: "20px", wordBreak: "break-word"}}>{screenNames?.screen3}</h6>
                                                        </div>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </StyledContainer>
                                        <Box style={{margin: "4rem auto 0 auto"}} sx={{ textAlign: 'center' }}>
                                            <ButtonGradient
                                                onClick={() => {
                                                    sliderRef.current.slickNext();
                                                }}
                                                backgroundColor={`rgba(${mobButtonColor?.r}, ${mobButtonColor?.g}, ${mobButtonColor?.b}, ${mobButtonColor?.a})`}
                                                title={'Next'} 
                                                style={{backgroundColor: mobButtonColor, width: "3rem", height: "1.5rem"}}
                                                textStyles={{
                                                    fontSize: "0.8rem"
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                                            <Typography variant='subtitle1' color='#323F4B' fontSize={'0.8rem'} fontWeight={600} sx={{ textDecorationLine: 'underline' }}>Skip</Typography>
                                        </Box>
                                    </div>: <div
                                        style={{
                                            width: "100%",
                                            height: "90%",
                                            display: "flex",
                                            flexDirection: "column",
                                            border: "1px solid #ddd",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "20%",
                                                backgroundColor: `rgba(${mobTopBannerColor?.r}, ${mobTopBannerColor?.g}, ${mobTopBannerColor?.b}, ${mobTopBannerColor?.a})`,
                                                padding: "20px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <img style={{width: "25%"}} src={imageUrl(mobLogo)} alt="logo"/>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "85%",
                                                marginTop: "-5%",
                                                borderTopRightRadius: "20px",
                                                borderTopLeftRadius: "20px",
                                                backgroundColor: `#fff`,
                                                padding: "20px",
                                                display: "flex",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <span style={{paddingBottom: "5px"}}>{t('email')}</span>
                                            <input 
                                                disabled={true}
                                                placeholder="name@gmail.com"
                                                style={{
                                                    height: "40px",
                                                    backgroundColor: "#fff",
                                                    marginBottom: "20px",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ddd"
                                                }}
                                                id="input"
                                            />
                                            <span style={{paddingBottom: "5px"}}>{t('password')}</span>
                                            <input 
                                                disabled={true}
                                                placeholder="********"
                                                style={{
                                                    height: "40px",
                                                    backgroundColor: "#fff",
                                                    marginBottom: "20px",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ddd"
                                                }}
                                                type="password"
                                                id="input"
                                            />
                                            <span style={{paddingBottom: "5px"}}>{t('confirmPassword')}</span>
                                            <input 
                                                disabled={true}
                                                placeholder="********"
                                                style={{
                                                    height: "40px",
                                                    backgroundColor: "#fff",
                                                    marginBottom: "50px",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ddd"
                                                }}
                                                type="password"
                                                id="input"
                                            />
                                            <ButtonGradient 
                                                title={t('signUp')}
                                                backgroundColor={`rgba(${mobButtonColor?.r}, ${mobButtonColor?.g}, ${mobButtonColor?.b}, ${mobButtonColor?.a})`}
                                                style={{
                                                    backgroundColor: `rgba(${mobButtonColor?.r}, ${mobButtonColor?.g}, ${mobButtonColor?.b}, ${mobButtonColor?.a})`,
                                                    width: "100%",
                                                    fontSize: "12px"
                                                }}
                                                textStyles={{
                                                    fontSize: "12px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                            <div
                                style={{
                                    width: "115%",
                                    marginLeft: "-7%",
                                    padding: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "10%"
                                }}
                            >
                                <Typography style={{marginRight: "10px", fontSize: "0.8rem"}}>{t('welcomeScreens')}</Typography>
                                <IOSSwitch checked={showWelcome} onChange={()=>setShowWelcome(!showWelcome)} inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography style={{marginLeft: "10px", fontSize: "0.8rem"}}>{t('signInScreen')}</Typography>
                            </div>
                        </div>}
                        {type==="order"&&<div
                            style={{
                                width: "50%",
                                maxWidth: "300px",
                                height: `100%`,
                                backgroundColor: "#fff",
                                display: "flex",
                                flexDirection: "column",
                                
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #ddd",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        height: "20%",
                                        backgroundColor: whiteLabel?.mobile_top_banner_color||`rgba(${mobTopBannerColor?.r}, ${mobTopBannerColor?.g}, ${mobTopBannerColor?.b}, ${mobTopBannerColor?.a})`,
                                        padding: "20px",
                                        paddingBottom: "40px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-end"
                                    }}
                                >
                                    <div 
                                        style={{
                                            display: "flex",
                                            width: "30px",
                                            height: "30px",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <ArrowBackIosIcon style={{color: "#fff"}} />
                                    </div>
                                    <div
                                        style={{
                                            textTransform: "uppercase",
                                            color: "#fff",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {translate('createOrder')}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "30px",
                                            height: "30px",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <NotificationsIcon style={{color: "#fff"}} />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "85%",
                                        marginTop: "-5%",
                                        borderTopRightRadius: "20px",
                                        borderTopLeftRadius: "20px",
                                        backgroundColor: `rgba(${orderBackgroundColor?.r}, ${orderBackgroundColor?.g}, ${orderBackgroundColor?.b}, ${orderBackgroundColor?.a})`,
                                        padding: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                        overflowY: "auto"
                                    }}
                                >
                                    {
                                        orderForm?.map(order=>(
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginBottom: "20px"
                                                }}
                                            >
                                                <span>{order?.label}</span>
                                                <input 
                                                    disabled={true}
                                                    placeholder={order?.placeholder||`Enter ${order?.label}`}
                                                    style={{
                                                        height: "40px",
                                                        backgroundColor: "#fff",
                                                        border: "none",
                                                        marginBottom: "20px"
                                                    }}
                                                    id="input"
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>}
                        {type==="email"&&<div
                            style={{
                                width: "100%",
                                height: `100%`,
                                backgroundColor: `rgba(${emailBackgroundColor?.r}, ${emailBackgroundColor?.g}, ${emailBackgroundColor?.b}, ${emailBackgroundColor?.a})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                border: "1px solid #ddd"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "70%",
                                    height: "70%",
                                    backgroundColor: "#fff",
                                    borderRadius: "5px",
                                    flexDirection: "column",
                                    border: "1px solid #d1d1d1"
                                }}
                            >
                                <div
                                    style={{
                                        height: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <img style={{width: "auto", height: "100%"}} src={imageUrl(emailLogo)} alt="logo"/>
                                </div>
                                <div
                                    style={{
                                        padding: "20px 40px",
                                    }}
                                >
                                    <p>{t('hello')} ,</p>
                                    <p>{t('sampleContent')}</p>
                                </div>
                            </div>
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td class="content-block" align="center">
                                        <span class="site-link">
                                            <a style={{fontWeight: "bold", color: "#fab316", textDecoration: "underline"}}>{window.location.host }</a>
                                        </span>
                                        <br/>
                                            <span style={{color: "rgb(153, 153, 153)", fontSize: "12px"}}>{ emailTemplate.address_line_1 }, { emailTemplate.address_line_2 }, { emailTemplate.city }, { emailTemplate.state }, { emailTemplate.zip_code }, { emailTemplate.country }</span>
                                            <br/>
                                            <span style={{color: "rgb(153, 153, 153)", fontSize: "12px"}}>Contact Number: { emailTemplate.contact_number }</span>
                                    </td>
                                </tr>
                            </table>
                        </div>}
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "10%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <ButtonGradient 
                            title="Cancel"
                            onClick={onClose}
                        />
                        <ButtonGradient 
                            title="Submit"
                            onClick={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default PreviewModal;