import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ImageZoom from '../../common/image-zoom'
import { Avatar } from '@mui/material'
import { formatTime } from '../../utils/CommonFunction'
import React, { forwardRef } from 'react'
import { UserAction as ApiCall } from '../../redux/action'
import { ROUTER_NAME } from '../../router/routers'

const NotificationCard = ({ notification, setLastElement, index }) => {
    const navigate = useNavigate()
    const { data: notes } = useSelector(state => state.notesList_vendor)
    const dispatch = useDispatch()
    const markAsRead = (id) => dispatch(ApiCall.markAsRead(id))

    return <div className="card" ref={setLastElement} data-card-index={index}>
            <span className={
                notification?.is_read
                    ? 'notification-status notification-bubble-white'
                    : 'notification-status notification-bubble-yellow'
            }/>
        <ImageZoom
            imageSrc={notification?.profile?.profile_image}
            altSrc={notification?.profile?.profile_image}
        >
            <Avatar
                src={notification?.profile?.profile_image}
                sx={{ borderRadius: '5px' }}
            />
        </ImageZoom>
        <div className="notification-body"
             onClick={() => {
                 if (!notification.is_read) {
                     markAsRead(notification.id)
                 }
                 if (notification.vendor_notes) {
                     notification?.thread_id && navigate(`${ROUTER_NAME.notesDetail}/${notification?.thread_id}`, { state: { scroll: true, note: notification } })
                 } else {
                    notification.order_id && navigate(`${ROUTER_NAME.orderDetail}/${notification.order_id}`, { state: { scroll: true } })
                 }
             }}
        >
            <span className="notification-title">
              {notification.content}
            </span>
            <span className="notification-time">{formatTime(notification?.created)} </span>
        </div>
    </div>
}
export default forwardRef(NotificationCard)
