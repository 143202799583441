const userMobileEndpoints = {
    //routes url
    landing: '/customer',
    editor: '/customer/editor',
    welcome: '/customer/welcome',
    signin: '/customer/signin',
    signup: '/customer/signup',
    forgot_password: '/customer/forgot-password',
    reset_password: '/customer/reset-password',
    profile: '/customer/profile',
    updateProfile: '/customer/update',
    changePassword: '/customer/change-password',
    terms: '/customer/terms',
    privacy: '/customer/privacy',
    quotes: '/customer/quotes',
    quotes_detail: '/customer/quotes/:productId',
    quotes_order: '/customer/quotes/:productId/order',
    settings: '/customer/settings',
    notifications: '/customer/notifications',
    delete_account: '/customer/delete',
    createOrder: '/customer/create-order',
    vendors: '/customer/vendors',
    paymentMethod: '/customer/payment-method',
    message: '/customer/message',
    orderDetails: '/customer/order/:id',
    editOrder: '/customer/edit-order/:id',
    orderQuote: '/customer/order-quote/:id',
    addNewCard: '/customer/add-new-card',
    editCard: '/customer/edit-card/:id',
    addNewBusinessCard: '/customer/add-new-business-card',
    editBusinessCard: '/customer/edit-business-card/:id',
    userNotification: '/customer/user-notification',
    addNewOrder: '/customer/add-new-order',
    // Api url,
    loginApi: 'accounts/login/token/',
    signupApi: 'accounts/signup/',
    ordersApi: 'orders/',
    profileApi: 'accounts/user-profile/',
    quotesApi: 'quotes/',
    quotesPaymentCardModeApi: 'stripe/send-payments/quote/',
    quotesPaymentOtherModeApi: 'payment/',
    quotesChatApi: 'chats/firestore/quote-rooms/create/',
    getOTP: 'get-otp/',
    validateOTP: 'validate-otp/',
    resetPassAPI: 'password-change/',
    deleteAPI: 'delete-user/',
    sendOTPAPI: 'accounts/phone-numbers/send-otp/',
    sendOTPAPISignUp: 'send_otp_user/',
    verifyOTPAPI: 'accounts/phone-numbers/verify-otp/',
    verifyOTPAPISignUP: 'validate_otp_user/',
    termsAndConditionsAPI: 'terms-and-conditions/',
    privacyPolicyAPI: 'privacy-policy/'
};

const dealerTechnicianEndpoints = {
    //routes url
    landing: '/dealer-technician',
    editor: '/dealer-technician/editor',
    welcome: '/dealer-technician/welcome',
    signin: '/dealer-technician/signin',
    signup: '/dealer-technician/signup',
    forgot_password: '/dealer-technician/forgot-password',
    reset_password: '/dealer-technician/reset-password',
    profile: '/dealer-technician/profile',
    updateProfile: '/dealer-technician/update',
    changePassword: '/dealer-technician/change-password',
    terms: '/dealer-technician/terms',
    privacy: '/dealer-technician/privacy',
    quotes: '/dealer-technician/quotes',
    quotes_detail: '/dealer-technician/quotes/:productId',
    quotes_order: '/dealer-technician/quotes/:productId/order',
    settings: '/dealer-technician/settings',
    notification: '/dealer-technician/notifition',
    notifications: '/dealer-technician/notifications',
    delete_account: '/dealer-technician/delete',
    createOrder: '/dealer-technician/create-order',
    vendors: '/dealer-technician/vendors',
    message: '/dealer-technician/message',
    orderDetails: '/dealer-technician/order/:id',
    editOrder: '/dealer-technician/edit-order/:id',
    orderQuote: '/dealer-technician/order-quote/:id',
    addNewCard: '/dealer-technician/add-new-card',
    editCard: '/dealer-technician/edit-card/:id',
    addNewBusinessCard: '/dealer-technician/add-new-business-card',
    editBusinessCard: '/dealer-technician/edit-business-card/:id',
    userNotification: '/dealer-technician/user-notification',
    addNewOrder: '/dealer-technician/add-new-order',
    // Api url,
    loginApi: 'accounts/login/token/',
    signupApi: 'accounts/signup/',
    ordersApi: 'orders/',
    profileApi: 'accounts/user-profile/',
    quotesApi: 'quotes/',
    quotesChatApi: 'chats/firestore/quote-rooms/create/',
    getOTP: 'get-otp/',
    validateOTP: 'validate-otp/',
    resetPassAPI: 'password-change/',
    deleteAPI: 'delete-user/',
    sendOTPAPI: 'accounts/phone-numbers/send-otp/',
    sendOTPAPISignUp: 'send_otp_user/',
    verifyOTPAPI: 'accounts/phone-numbers/verify-otp/',
    verifyOTPAPISignUP: 'validate_otp_user/',
    termsAndConditionsAPI: 'terms-and-conditions/',
    privacyPolicyAPI: 'privacy-policy/'
};

const vendorEndpoints = {
    // API URI
    notesListApi: 'vendor_notes/api/vendor_notes/',
    createNotesApi: 'vendor_notes/create-vendor-notes/',
    commentsListApi: 'vendor_notes/add-comments/',
    addCommentApi: 'vendor_notes/add-comments/',
    orderApi: 'orders/',
    orderUpdate: 'orders-update/',
    quoteApi: 'quotes/',
    chatRoomApi: 'chats/firestore/order-rooms/create/',
    childAccountsListApi: 'child-account-management/child-accounts/',
    createChildAccountsApi: 'child-account-management/child-accounts/create/',
    deleteChildAccountApi: 'child-account-management/child-accounts/delete/',
    verifyChildAccountsApi: 'child-account-management/child-accounts/verify/',
    sendMobileNumberOtp: 'accounts/phone-numbers/send-otp/',
    verifyMobileNumberOtp: 'accounts/phone-numbers/verify-otp/',
    sendEmailOtp: 'get-otp/',
    verifyEmailOtp: 'validate-otp/',
    createWhiteLabelPartnerApi: 'white-label-partner/create/',
    vendorsList: 'vendors/all/',
    teamListApi: 'team-member/',
    inviteTeamMember: 'users/add-team-member/',
    teamDetailApi: 'accounts/team-member-details/',
    deleteTeamMember: 'accounts/profile-delete/',
    updateWarratyStatus: 'warranty-status/'
};

const EndPoint = { userMobileEndpoints, vendorEndpoints, dealerTechnicianEndpoints };

export default EndPoint;

