
import React, { useEffect, useState } from 'react'
import usePagination from '../../common/table/Pagination'
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    styled,
    Typography
} from '@mui/material'
import { Table } from 'antd'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Search from '../../common/search'
import { json, useNavigate } from 'react-router-dom'
import { ROUTER_NAME } from '../../router/routers'
import { connect, useDispatch, useSelector } from 'react-redux'
import { formatDate } from '../../utils/CommonFunction'
import {
    convertPxToRem,
    filterQuoteStatus,
    filterQuoteStatusColor,
    getValueFromObjectByKeys,
    handleStatus,
    translate
} from '../../helper'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { Colors } from '../../themes/Colors'
import { fetchOrderListApiCall } from './Action'
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Utils from '../../utils'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { UserAction } from '../../redux/action'
import TextBase from '../../common/text'
import { successOrderListReducer } from './Slice/orderListSlice'

const PageBox = styled('div')(({ theme }) => ({
    backgroundColor: '#f9f9f9',
    height: 'calc(100vh - 100px)',
    overflowY: 'auto',
    padding: '1.875rem 2rem 0.625rem',
    '& .MuiOutlinedInput-input': {
        'padding': '10px 14px'
    },
    '& .MuiButton-root': {
        'backgroundColor': 'white !important',
        'color': 'black',
        'border': '1px solid #C8C8C8 !important'
    },
    '& .MuiButtonGroup-root': {
        'boxShadow': 'none !important',
        'height': '50px'
    },
    '& .checked': {
        background: 'white',
        borderRadius: '50px',
        padding: '5px',
        fontSize: '30px',
        color: 'green'
    },
    '& .unchecked': {
        background: 'white',
        borderRadius: '50px',
        padding: '5px',
        fontSize: '30px'
    }
}))

const ActionButton = styled(Button)(({}) => ({
    'background': '#F3F4F6',
    'color': '#6B7280 !important',
    'textTransform': 'capitalize',
    'fontWeight': '400 !important',
    '& .icon': {
        'fontSize': '14px',
        'color': '#6B7280',
        'marginLeft': '10px'
    }
}))

let socket = null

const OrderPage = (props) => {

    const SortByOption = [
        { label: translate('newestToOldest'), value: '-created_at' },
        { label: translate('oldestToNewest'), value: 'created_at' }
    ]
    const { userInfo: { user, token } } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { data, loading, total } = useSelector(props => props.orderList_vendor)
    const isUserServiceWriter = user?.role === 'SW'
    const isUserTeamMember = props.userInfo?.profile?.team_member

    const [filters, setFilters] = React.useState({
        pageNo: 1,
        limit: 15,
        searchKey: '',
        ordering: '-created_at'
    })
    const [openSortByMenu, setOpenSortByMenu] = React.useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const anchorRef = React.useRef(null)

    const [paging] = usePagination(total, filters, setFilters)

    React.useEffect(() => {
        if (user?.vendor?.id) {
            setOrderLoading(true)
            fetchOrderListApiCall({
                vendors__id: user?.vendor?.id,
                ...filters
            }, dispatch, [])
        }
        
    }, [user, filters])

    useEffect(() => {
        getUpdates();
        document.title = 'Order'
        return () => {
            if (socket) {
                socket.close()
            }
        }
    }, [])

    useEffect(() => {
        if (!loading) {
            setOrderLoading(false)
        }
    }, [loading])

    useEffect(() => {
        if (socket) {
            socket.onmessage = (event) => {
                if (window.location.pathname === '/order') {
                    let newOrder = JSON.parse(event?.data)?.order||{}
                    new CreateNewOrderList(newOrder)
                }
            }
        }
    }, [data])

    let orderList = JSON.parse(JSON.stringify(data));
    function CreateNewOrderList(order) {
        let newTotal = total
        
        let isExist = false
        let newData = orderList.map((item) => {
            if (item.id === order?.order_id) {
                isExist = true
                return {
                    id: order?.order_id,
                    ...order
                }
            }
            return item
        })
        
        if (!isExist) {
            let orderArray = [
                {id: order?.order_id, ...order}, 
                ...newData
            ]
            let sortedOrderArrayByOrderId = orderArray.sort((a, b) => b.id - a.id)
            
            newData = [...sortedOrderArrayByOrderId]
            newTotal += 1
            newData.pop()
        }
        dispatch(successOrderListReducer({
            data: newData,
            total: newTotal,
            loading: false
        }))

    }

    const getUpdates = () => {
        socket = new ReconnectingWebSocket(
            `wss://${Utils.constants.API_URL.replace('https://', '')}ws/orders/?token=${token}`
        )

        socket.addEventListener('open', () => {
        })

        
    }
    const handleMenuItemClick = (val) => {
        setFilters(
            {
                ...filters,
                pageNo: 1,
                limit: 10,
                ordering: val
            }
        )
        setOpenSortByMenu(false)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpenSortByMenu(false)
    }

    const handleFullfilledBy = (row) => {
        const quote = row.quotes?.filter((q) => q.status === 'ACCEPTED')
        const pending = row.quotes?.find((q) => q.status === 'SENT')
        return quote?.length > 0
            ? quote?.[0]?.user?.name
            : pending?.id
                ? pending?.user?.name
                : 'N/A'
    }
    const orderColumns = [
        {
            title: translate('dateCreated'),
            dataIndex: 'created_at',
            align: 'left',
            key: 'created_at',
            render: (__, row) => {
                return formatDate(row?.created_at) || ''
            }
        },
        {
            title: translate('customerPo'),
            dataIndex: 'id',
            align: 'left',
            key: 'id',
            render: (__, row) => {
                return (
                    <Typography
                        color={'rgb(81, 125, 152)'}
                        fontSize={'14px'}
                        sx={{
                            cursor: 'pointer',
                            textDecorationLine: 'underline'
                        }}
                        onClick={() => {
                            navigate(`${ROUTER_NAME.orderDetail}/${row.id}`)
                        }}
                    >
                        {row.id}
                    </Typography>
                )
            }
        },
        {
            title: translate('orderName'),
            dataIndex: 'name',
            align: 'left',
            key: 'name',
            render: (__, row) => {
                return row.name || ''
            }
        },
        {
            title: translate('status'),
            dataIndex: 'status',
            align: 'left',
            key: 'status',
            render: (__, row) => {
                const statusLabel = handleStatus(row, isUserServiceWriter, isUserTeamMember)
                const statusColor = filterQuoteStatusColor(statusLabel, isUserServiceWriter, isUserTeamMember)
                const status = filterQuoteStatus(statusLabel, isUserServiceWriter, isUserTeamMember)

                const deliverLabelMap = {
                    'instore': translate('acceptedInStorePickup'),
                    'delivery': translate('acceptedDelivery')
                }
                return <>
                    <Typography variant="p" fontWeight={'400'} sx={{ textTransform: 'capitalize' }}
                                color={statusColor}>
                        {status}
                    </Typography>
                    {
                        row?.status === 'INPROGRESS' && row?.quotes?.length === 0 &&
                        <Typography variant="p" fontWeight={'400'} color={'#000'}>
                            {` - ${row?.order_vendor_note_user_name?.trim()}`}
                        </Typography>
                    }
                    {
                        status === 'Paid' &&
                        <Typography variant="p" fontWeight={'400'} color={'#000'}>
                            {` - ${deliverLabelMap[row?.delivery_method]}`}
                        </Typography>
                    }
                    {
                        status === 'SERVICE ACCEPTED' &&
                        <Typography display={'block'} variant="p" fontWeight={'400'} color={'#000'}>
                            {deliverLabelMap[row?.delivery_method]}
                        </Typography>
                    }
                </>
            }
        },
        {
            title: translate('warrantyStatus'),
            dataIndex: 'status',
            align: 'left',
            key: 'status',
            render: (__, row) => {
                return (
                    row?.warranty ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <CheckIcon className="checked"/> {row.warranty_user_name}
                        </div>
                    ) : (
                        <CheckBoxOutlineBlankIcon className="unchecked"/>
                    )
                )
            }
        },
        {
            title: translate('fulfilledBy'),
            dataIndex: 'email',
            align: 'left',
            key: 'email',
            render: (__, row) => {
                return handleFullfilledBy(row) || 'N/A'
            }
        },
        {
            title: translate('paymentInfo'),
            dataIndex: 'status',
            align: 'left',
            key: 'status',
            render: (__, row) => {
                return row?.quotes?.[0] && row?.quotes?.[0]?.status === 'ACCEPTED' ? row?.quotes?.[0]?.stripe_charge_id !== 'NS' ? translate('stripe') : translate('accountBilling') : ''
            }
        },
        {
            title: translate('orderInfo'),
            dataIndex: 'orderInfo',
            align: 'left',
            key: 'orderInfo',
            render: (__, row) => {
                return (
                    <ActionButton
                        onClick={() => {
                        }}
                    >
                        {translate('details')}
                        <ArrowForwardIosIcon className="icon"/>
                    </ActionButton>
                )
            }
        }
    ]

    return (
        <PageBox style={{ backgroundColor: '#fff' }}>
            <Box fontSize={'1.5rem'} fontWeight={'600'}>
                <TextBase
                    style={{ marginBottom: 20 }}
                    fontWeight={600}
                    size={convertPxToRem(24)}
                    color={Colors.titlePage}
                >
                    {translate('orders')}
                </TextBase>
            </Box>
            <Stack style={{ padding: '20px' }} alignItems={'center'} justifyContent={'space-between'} direction="row"
                   spacing={2}>
                <Box>
                    <Search
                        placeholder={translate('searchOrders')}
                        setQuery={(value) => {
                            setFilters({
                                ...filters,
                                pageNo: 1,
                                searchKey: value
                            })
                        }}
                        sx={{ width: '350px' }}
                    />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        style={{
                            boxShadow: 'none'
                        }}
                    >
                        <Button
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            sx={{
                                textTransform: 'capitalize'
                            }}
                            onClick={() => setOpenSortByMenu((prevOpen) => !prevOpen)}
                        >{translate('sortBy')} <p style={{
                            textTransform: 'uppercase',
                            marginBottom: 0
                        }}>{SortByOption.find(sort => sort.value == filters.ordering)?.label}</p></Button>
                        <Button
                            size="small"
                            onClick={() => setOpenSortByMenu((prevOpen) => !prevOpen)}
                        >
                            <ArrowDropDownIcon/>
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 9999,
                            textTransform: 'uppercase'
                        }}
                        open={openSortByMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom'
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem>
                                            {SortByOption.map((option, index) => (
                                                <MenuItem
                                                    key={option.value}
                                                    selected={option.value === filters.ordering}
                                                    onClick={(event) => handleMenuItemClick(option.value)}
                                                    style={{
                                                        color: option.value === filters.ordering ? Colors.mainColor : '#000'
                                                    }}

                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Stack>
            <Table
                rowKey={record => record.id}
                style={{ width: '100%', padding: '20px' }}
                columns={orderColumns}
                dataSource={data}
                className="table"
                bordered={false}
                loading={orderLoading}
                total={total}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: event => {
                            navigate(`${ROUTER_NAME.orderDetail}/${record.id}`)
                        }
                    }
                }}
            />
            {paging}
        </PageBox>
    )

}

const mapStateToProps = (state) => {
    return {
        userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo'])
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: () => dispatch(UserAction.getUnreadNotifCount()),
        updateNotifications: (data) => dispatch(UserAction.updateUnreadNotifCount(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderPage)
