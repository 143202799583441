import React, { useRef } from 'react'
import { Avatar, Box, Checkbox, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material'
import { EmailIcon } from '../../assets/image/svg/email.js'
import phone from '../../../src/assets/image/svg/phone.svg'
import addressLoc from '../../../src/assets/image/svg/location.svg'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateWarrantyApiCall } from './Action'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../common/Loader'
import { orderStatusMap, quotesStatus } from '../../defined'
import {
    filterQuoteStatus,
    filterQuoteStatusColor,
    getValueFromObjectByKeys,
    handleStatus,
    translate
} from '../../helper'
import ImageSliderComponent from '../../common/image-slider/ImageSlider.component'
import APIManager from '../../connection/ApiManager'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import 'firebase/compat/firestore'
import ImageZoom from '../../common/image-zoom'
import './style.css'
import StartThreadModal from './StartThreadModal'
import ReplyChatModal from './ReplyChatModal'
import QuotesModal from './QuotesModal'
import { PageBox, SendButton, StartButton } from './components'
import Utils from '../../utils'
import { setOrderDetailsSlice, setOrderDetailsSliceData } from '../../mobile/modules/Orders/Slice/ordersDetailSlice'
import MessagesList from './MessagesList'
import InfoCard from './InfoCard'
import ReconnectingWebSocket from 'reconnecting-websocket'

const OrderDetailPage = (props) => {
    const { userInfo: { profile: { user, vendor } = {} } = {} } = props
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const firebaseSnapRef = useRef(null)
    const { data: { orderDetail, warrantyStatus, quotesData }, } = useSelector((state) => state.orderDetail)
    const setOrderDetails = (data) => dispatch(setOrderDetailsSlice({ key: 'orderDetail', data }))
    const setWarrantyStatus = (data) => dispatch(setOrderDetailsSlice({ key: 'warrantyStatus', data }))

    const [openStartThread, setOpenStartThread] = React.useState(false)
    const [openSendQuote, setOpenSendQuote] = React.useState(false)
    const [openReply, setOpenReply] = React.useState(false)
    const messagesEnd = React.useRef()
    const [loading, setLoading] = React.useState(true)
    const [messages, setMessages] = React.useState([])
    const [messagesLoading, setMessagesLoading] = React.useState(true)
    const [changingWarrantyStatus, setChangingWarrantyStatus] = React.useState(false)
    const deliverLabelMap = {
        'instore': translate('acceptedInStorePickup'),
        'delivery': translate('acceptedDelivery')
    }
    const isUserTeamMember = props.userInfo?.profile?.team_member
    const isUserServiceWriter = user?.role === 'SW'
    const statusLabel = orderDetail && handleStatus(orderDetail, isUserServiceWriter, isUserTeamMember)
    const statusColor = filterQuoteStatusColor(statusLabel, isUserServiceWriter, isUserTeamMember)
    const status = filterQuoteStatus(statusLabel, isUserServiceWriter, isUserTeamMember)
    const isUserActionRequired = isUserServiceWriter && status === 'Action Required'
    const [swActionLoading, setSwActionLoading] = React.useState(false)

    if (!id) {
        navigate('/order')
    }
    const wsNotifications = useRef(null)

    const acceptServiceAndPay = async () => {
        try {
            setSwActionLoading(true)
            await APIManager.getInstance().acceptServiceAndPay(quotesData.id)
            Utils.showAlert(1, 'Service Accepted Successfully')
            setOrderDetails({
                ...orderDetail,
                status: 'SERVICE ACCEPTED'
            })
        } catch (e) {
            Utils.showAlert(2, 'OOPS! something went wrong! Please try again')
        } finally {
            setSwActionLoading(false)
        }
    }

    const getData = async () => {
        if (!id) return
        try {
            const resDetail = await APIManager.getInstance().getOrderDetail(id)
            const orderDetail = resDetail?.data
            dispatch(setOrderDetailsSliceData({ orderDetail }))
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const vendors = orderDetail?.vendors
    const vendorId = vendors ? (vendors[0]?.id || vendors[0]) : null
    const quotes = orderDetail?.quotes || []
    const quoteItem = quotes.find((x) => x?.vendor?.id === vendorId)
    const isSentQuote = !!quoteItem
    const isVendor = (orderDetail?.vendors || []).find(x => x === vendorId || x === vendor?.id)

    const isOrderFulfillment =
        orderDetail?.status === orderStatusMap.PROCESSING ||
        orderDetail?.status === orderStatusMap.DELIVERED
    const isQuoteDecline = quoteItem?.status === quotesStatus.DECLINED

    const handleChangeWarranty = (evt) => {
        updateWarrantyApiCall(
            orderDetail.id, { 'warranty': evt.target.checked }, setChangingWarrantyStatus, (data) => setWarrantyStatus({
                status: data.warranty,
                name: data.warranty_user_name
            })
        )
    }
    const updatePageInViewStatus = (id, details_open = 'false') => {
        id && APIManager.getInstance().updateInprogress({ details_open }, id)
    }

    const vinNames = ['vin', 'vin number', 'identification number', 'vin #', 'vin#', 'serial number', 's/n', 'vin no:', 'identification no:', 'serial no:']

    React.useEffect(() => {
        (async () => {
            document.title = 'Order'
            await getData()
            await updatePageInViewStatus(id, 'true')
        })()

        return () => {
            firebaseSnapRef.current?.unsubscribe()
            updatePageInViewStatus(id, 'false')
            if (wsNotifications.current) {
                wsNotifications.current.close()
            }
        }
    }, [])

    React.useEffect(() => {
        if (location.state?.scroll) {
            messagesEnd.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location, orderDetail])

    React.useEffect(() => {
        if (!id) {
            return
        }
        wsNotifications.current = new ReconnectingWebSocket(
            `wss://${Utils.constants.API_URL.replace('https://', '')}ws/orders/${id}/?token=${props.userInfo.token}`
        )
        wsNotifications.current.onmessage = (event) => {
            if (window.location.pathname.includes('order-detail')) {
                getData()
            }
        }
    }, [id])

    const dynamicInfo = orderDetail?.dynamic_attributes &&
        Object.keys(orderDetail?.dynamic_attributes)?.length > 0
        && Object.keys(orderDetail?.dynamic_attributes)?.map(e => (
            {
                label: e,
                value: orderDetail?.dynamic_attributes?.[e],
                defaultValue: 'N/A',
                copyLabel: vinNames.includes(e.toLocaleLowerCase()),
            }
        ))

        let basicInfo = [
        {
            label: translate('customerPo'),
            value: orderDetail?.id,
            defaultValue: 'N/A',
        },
        {
            label: translate('location'),
            value: orderDetail?.location_name,
            defaultValue: 'N/A',
        },
        {
            label: translate('quantity'),
            value: orderDetail?.quantity,
            defaultValue: 'N/A',
        },
        {
            label: translate('address'),
            value: orderDetail?.shipping_address,
            defaultValue: 'N/A',
        },
        {
            label: translate('numberScanner'),
            value: orderDetail?.vin,
            defaultValue: 'N/A',
            copyLabel: true,
        },
        {
            label: translate('deliveryMethod'),
            value: translate(orderDetail?.delivery_method),
            defaultValue: 'N/A',
        },
        {
            label: translate('status'),
            value: status,
            defaultValue: '',
            valueTypoProps: {
                color: statusColor
            }
        },
    ]

    if (dynamicInfo) {
        let newInfo = [
            ...dynamicInfo,
            {
                label: translate('status'),
                value: status,
                defaultValue: '',
                valueTypoProps: {
                    color: statusColor
                }
            },
        ]
        basicInfo.splice(6, 1, ...newInfo)
    }

    if (['Paid', 'SERVICE ACCEPTED'].includes(status)) {
        basicInfo.push({
            label: 'Delivery Method',
            value: deliverLabelMap[orderDetail.delivery_method]
        })
    }
    const showTextButtons = orderDetail?.vendors?.includes(vendor?.id)
    return (
        <>
            <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '0.75rem 1.5625rem'
            }}>
                <Box fontSize={'1.5rem'}
                     fontWeight={'600'}
                     display="flex"
                     alignItems="center"
                >
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon style={{ marginRight: '10px', fontSize: '28px' }}/>
                    </IconButton>
                    {translate('orderDetails')}
                </Box>
                <PageBox style={{ backgroundColor: '#fff' }}>
                    {
                        !loading && orderDetail ? (
                            <Grid
                                container
                                className="details-containerorderdetails"
                            >
                                <Grid item md={6} xs={10}>
                                    <ImageSliderComponent
                                        images={orderDetail?.images}
                                        showThumbnails
                                        style={{ position: 'sticky', top: '16px', padding: 0 }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={10} className="details">
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                        }}
                                    >
                                        <Box style={{width: "60%"}} marginY={'10px'}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography variant="h6" fontSize={'24px'} fontWeight={'600'}>{translate('customerInfo')}</Typography>
                                            </Box>
                                            <Box className="userinfo" marginY={'15px'}>
                                                <ImageZoom
                                                    imageSrc={orderDetail?.user?.profile?.profile_image}
                                                    altSrc={orderDetail?.user?.profile?.profile_image}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            background: 'gray',
                                                            height: 50,
                                                            width: 50,
                                                            borderRadius: '5px'
                                                        }}
                                                        variant="rounded"
                                                        src={orderDetail?.user?.profile?.profile_image}
                                                    />
                                                </ImageZoom>
                                                <Typography fontWeight={'bold'} className="usercontact">{orderDetail?.user?.profile?.full_name || orderDetail?.user?.name}</Typography>
                                            </Box>
                                            <Box className="userinfo">
                                                <Avatar className="useravatar" variant="rounded">
                                                    <EmailIcon/>
                                                </Avatar>
                                                <Typography
                                                    className="usercontact">{orderDetail?.user?.email || orderDetail?.user?.profile?.email}</Typography>
                                            </Box>
                                            <Box className="userinfo">
                                                <Avatar className="useravatar" variant="rounded">
                                                    <img src={phone}/>
                                                </Avatar>
                                                <Typography className="usercontact">
                                                    {
                                                        orderDetail?.user?.profile?.phone_number?.country_code &&
                                                        orderDetail?.user?.profile?.phone_number?.national_number ?
                                                            `+${orderDetail?.user?.profile?.phone_number?.country_code}${orderDetail?.user?.profile?.phone_number?.national_number}` : 'N/A'
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box className="userinfo">
                                                <Avatar className="useravatar" variant="rounded">
                                                    <img src={addressLoc}/>
                                                </Avatar>
                                                <Typography className="usercontact">
                                                    {orderDetail?.user?.profile?.address}, {orderDetail?.user?.profile?.city},{' '}
                                                    {orderDetail?.user?.profile?.state} {orderDetail?.user?.profile?.zip_code},{' '}
                                                    {orderDetail?.user?.profile?.country}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{width: "40%"}} marginY={'10px'}>
                                            <div
                                                style={{
                                                    padding: '10px',
                                                    border: "1px solid #000",
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                <Typography className="ordername">{translate('warranty')}</Typography>
                                                <Typography fontSize={'16px'} fontWeight={'400'}>
                                                    {
                                                        changingWarrantyStatus ? <CircularProgress/> :
                                                            (
                                                                <>
                                                                    <Checkbox checked={warrantyStatus.status}
                                                                            disabled={changingWarrantyStatus} style={{
                                                                        color: warrantyStatus.status ? '#4CE865' : '#000',
                                                                        padding: '5px 15px 5px 0px'
                                                                    }} onChange={handleChangeWarranty}/>
                                                                    {warrantyStatus.name ? `${translate('markedBy')} ${warrantyStatus.name}` : translate('notMarked')}
                                                                </>
                                                            )
                                                    }

                                                </Typography>

                                            </div>
                                        </Box>
                                    </div>
                                    <Box style={{marginBottom: "0px", backgroundColor: "#F3F4F6", padding: "10px 10px 30px 10px"}} marginY={'10px'}>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography variant="h6" fontSize={'24px'}
                                                        fontWeight={'600'}>{translate('orderInformation')}</Typography>
                                            <Divider style={{ width: '60%' }}/>
                                        </Box>
                                        <Typography className="ordername"
                                                    marginY={'10px'}>{translate('orderName')} : {orderDetail.name}</Typography>
                                        <Box>
                                            <Typography className="ordername"
                                                        marginBottom={'2px'}>{translate('productDescription')}</Typography>
                                            <Typography fontSize={'16px'} fontWeight={'600'}
                                                        variant="p">{orderDetail.description}</Typography>
                                        </Box>
                                    </Box>
                                    <InfoCard style={{marginTop: 0}} info={basicInfo}/>
                                    <InfoCard info={
                                        [
                                            {
                                                label: translate('lastQuote'),
                                                value: quotesData.quote ? `USD ${quotesData.quote}` : 'N/A',
                                                defaultValue: ''
                                            },
                                            {
                                                label: translate('lastUpdate'),
                                                value: quotesData?.updatedAt || 'N/A',
                                                defaultValue: ''
                                            },
                                        ]
                                    }/>

                                    <div ref={messagesEnd}>
                                        <MessagesList
                                            setOpenReply={setOpenReply}
                                            orderDetail={orderDetail}
                                            vendorId={vendorId}
                                            messages={messages}
                                            setMessages={setMessages}
                                            messagesLoading={messagesLoading}
                                            setMessagesLoading={setMessagesLoading}
                                        />
                                    </div>
                                    <Grid container spacing={2} marginY={'20px'}>
                                        <Grid item xs={6}>
                                            {
                                                !messagesLoading && showTextButtons && (messages?.length == 0) && (
                                                    <StartButton onClick={() => setOpenStartThread(true)}>
                                                        {translate('startThread')}
                                                    </StartButton>
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SendButton disabled={!isVendor} onClick={() => setOpenSendQuote(true)}>
                                                {
                                                    isQuoteDecline
                                                        ? translate('declinedStatus')
                                                        : isOrderFulfillment
                                                            ? translate('orderFulfillmentStatus')
                                                            : isSentQuote
                                                                ? translate('editQuoteStatus')
                                                                : translate('sendQuoteStatus')
                                                }
                                            </SendButton>
                                        </Grid>
                                    </Grid>
                                    {
                                        isUserServiceWriter && isUserActionRequired && quotesData &&
                                        <Grid container spacing={2} marginY={'20px'}>
                                            <Grid item xs={6}>
                                                <SendButton disabled={swActionLoading}
                                                            onClick={acceptServiceAndPay}>
                                                    {
                                                        swActionLoading ? <CircularProgress
                                                                style={{ width: '20px', height: '20px' }}/> :
                                                            translate('acceptServiceAndPay')
                                                    }
                                                </SendButton>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        ) : (
                            loading ? (
                                <Loader/>
                            ) : (
                                <div>{translate('noDetailFound')}</div>
                            )
                        )
                    }
                </PageBox>
            </div>
            <StartThreadModal
                isOpen={openStartThread}
                onClose={() => {
                    setOpenStartThread(false)
                }}
                orderDetail={orderDetail}
                user={user}
                vendorId={vendorId}
            />
            <QuotesModal
                isOpen={openSendQuote}
                onClose={() => {
                    setOpenSendQuote(false)
                }}
                onClickClose={() => {
                    setOpenSendQuote(false)
                }}
                orderDetail={orderDetail}
                refetch={() => {
                    setOpenSendQuote(false)
                    getData()
                }}
                user={user}
                vendorId={vendorId}
            />
            <ReplyChatModal
                isOpen={openReply}
                onClose={() => setOpenReply(false)}
                orderDetail={orderDetail}
                user={user}
                vendorId={vendorId}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
        vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    }
}

export default connect(mapStateToProps)(OrderDetailPage)
