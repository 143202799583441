import { convertPxToRem } from '../helper';
import { Modal } from 'antd';
import TextBase from '../common/text';
import { Colors } from '../themes/Colors';
import React, { useState } from 'react';
import LoginInput from '../common/login-input';
import { SVGImageComponentReact } from '../themes/Images';
import ButtonGradient from '../common/button-gradient';
import InputError from '../common/input-error/InputError.component';
import { useDispatch } from 'react-redux';
import { UserAction } from '../redux/action';
import Utils from '../utils';
import CloseIcon from '@mui/icons-material/Close';

const ChangePassword = (props) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  const IconEyeCurrentPassword = !showCurrentPassword
    ? SVGImageComponentReact.IconEyeOffPassword
    : SVGImageComponentReact.IconEyePassword;

  const IconEyePassword = !showPassword
    ? SVGImageComponentReact.IconEyeOffPassword
    : SVGImageComponentReact.IconEyePassword;

  const IconEyeConfirmPassword = !showConfirmPassword
    ? SVGImageComponentReact.IconEyeOffPassword
    : SVGImageComponentReact.IconEyePassword;

  const containsNumbers = (str) => {
    return Boolean(str.match(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/));
  };

  const validatePassword = (password) => {
    return password.length >= 8 && containsNumbers(password);
  };

  const checkIfError = (error) => {
    return error.length === 0;
  };

  const handleNext = async () => {
    const errors = formErrors;
    // Password validation
    // if (!currentPassword) {
    //   errors.old_password = ['Current password is required'];
    //   setFormErrors({ ...errors });
    // } else {
    //   errors.old_password = [];
    //   setFormErrors({ ...errors });
    // }
    //
    // if (!newPassword) {
    //   errors.new_password1 = ['New password is required'];
    //   setFormErrors({ ...errors });
    // } else {
    //   errors.new_password1 = [];
    //   setFormErrors({ ...errors });
    // }

    // Confirm password validation
    // if (!confirmPassword) {
    //   errors.new_password2 = ['Confirm password is required'];
    //   setFormErrors({ ...errors });
    // } else if (!validatePassword(confirmPassword)) {
    //   errors.new_password2 = ['Confirm password must be 8 characters long with 1 number.'];
    //   setFormErrors({ ...errors });
    // } else if (newPassword !== confirmPassword) {
    //   errors.new_password2 = ['Password and confirm password are not the same.'];
    //   setFormErrors({ ...errors });
    // } else {
    //   errors.new_password2 = [];
    //   setFormErrors({ ...errors });
    // }

    const formData = {
      old_password: currentPassword,
      new_password1: newPassword,
      new_password2: confirmPassword
    };

    try {
      setFormErrors({});
      await dispatch(UserAction.changePassword(formData));
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowCurrentPassword(false);
      setFormErrors({});
      Utils.showAlert(1, 'Password changed successfully');
      props.closeModal();
    } catch (errors) {
      if (
        errors.hasOwnProperty('old_password') ||
        errors.hasOwnProperty('non_field_errors') ||
        errors.hasOwnProperty('new_password1') ||
        errors.hasOwnProperty('new_password2')
      ) {
        setFormErrors(errors);
      }
    }
  };

  return (
    <Modal
      onCancel={() => props.closeModal()}
      closeIcon={<CloseIcon onClick={()=>props.closeModal()} />}
      visible={props.showChangePassword}
      footer={null}
      width={'40vw'}
      bodyStyle={{
        paddingInline: convertPxToRem(35),
        borderRadius: convertPxToRem(12)
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginBottom: convertPxToRem(30),
          paddingTop: convertPxToRem(10)
        }}>
        <TextBase size={convertPxToRem(32)} color={Colors.grey2} fontWeight={600}>
          changePassword
        </TextBase>
      </div>

      {/*  Current password */}
      <div style={{ marginBottom: convertPxToRem(35) }}>
        <TextBase size={16} fontWeight={400} color={Colors.black}>
          currentPassword
        </TextBase>
        <LoginInput
          value={currentPassword}
          onChange={(value) => setCurrentPassword(value)}
          placeholder="enterPassword"
          type={showCurrentPassword ? 'text' : 'password'}
          style={{ top: -20 }}
          rightElement={
            <IconEyeCurrentPassword
              className="cursor-pointer"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            />
          }
        />
        <InputError error={formErrors.old_password||formErrors.non_field_errors} />
      </div>

      {/* New Password */}
      <div style={{ marginBottom: convertPxToRem(35) }}>
        <TextBase size={16} fontWeight={400} color={Colors.black}>
          newPassword
        </TextBase>
        <LoginInput
          value={newPassword}
          onChange={(value) => setNewPassword(value)}
          placeholder="enterPassword"
          type={showPassword ? 'text' : 'password'}
          style={{ top: -20 }}
          rightElement={
            <IconEyePassword
              className="cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            />
          }
        />
        <InputError error={formErrors.new_password1} />
      </div>

      {/* Confirm password  */}
      <div style={{ marginBottom: convertPxToRem(35) }}>
        <TextBase size={16} fontWeight={400} color={Colors.black}>
        confirmPassword
        </TextBase>
        <LoginInput
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
          placeholder="enterPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          style={{ top: -20 }}
          rightElement={
            <IconEyeConfirmPassword
              className="cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          }
        />
        <InputError error={formErrors.new_password2} />
        <TextBase
          size={convertPxToRem(14)}
          color={Colors.grey}
          style={{ marginTop: 25 }}
          className="no-select">
          passwordConstraint
        </TextBase>
      </div>

      <div style={{ marginBottom: 19, marginTop: 20 }} className="flex-center-all max-width-100">
        <ButtonGradient
          onClick={handleNext}
          textStyle={{ fontWeight: 600 }}
          title="save"
          loading={false}
        />
      </div>
    </Modal>
  );
};

export default ChangePassword;
