import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Userpilot } from 'userpilot'
import Switch from 'react-switch';
import { Col, Row, Table, Dropdown, Pagination } from "antd"
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';

import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import {
  MESSAGE_TYPE,
  convertPxToRem,
  getCreditCardTypeString,
  messageManager,
  translate,
  getValueFromObjectByKeys
} from '../../helper';
import { SubscriptionCard } from '../../common/subscription-card';
import { DealerCopyCode } from '../../common/dealer-code-copy';
import { ActiveSubscription } from '../../common/active-subscription';
import { useGenerateDealerCode, useLockDealerCode } from '../../hooks/useVendors';
import { SVGImageComponentReact } from '../../themes/Images';
import { useGetProfile } from '../../hooks/useProfile';
import LoadingBase from '../../common/loading';
import {
  useCancelVendorSubscription,
  useGetTrialSubscriptionSettings,
  useGetVendorSubscriptionDetails,
  useGetVendorSubscriptionProducts,
  useReactivateVendorSubscription,
  useCancelChildSubscription,
  useReactivateChildSubscription,
  useCreateVendorChildSubscription
} from '../../hooks/useSubscriptions';
import { CreditCard } from '../../common/credit-card';
import { useGetPaymentMethods } from '../../hooks/usePayment';
import { SubscriptionHeader } from '../../common/subscription-header';
import { NewCardButton } from '../../common/new-card-button';
import { ConfirmModal } from '../../common/confirm-modal';
import { useCreateVendorSubscription } from '../../hooks/useSubscriptions';
import { useAddPaymentMethod } from '../../hooks/usePayment';
import { SuccessModal } from '../../common/success-subscription';
import LocalStorage from "../../defined/localStorage"

import { styles } from './styles';
import APIManager from '../../connection/ApiManager';
import { CircularProgress, Paper, Tooltip } from '@mui/material';
import { FormInput } from '../../common/form-input';
import VisaIcon from '../../assets/image/svg/payment/visa.svg';
import AmexIcon from '../../assets/image/svg/payment/amex.svg';
import DiscoverIcon from '../../assets/image/svg/payment/discover.svg';
import MastercardIcon from '../../assets/image/svg/payment/mastercard.svg';
import { formatCurrency } from '../../utils/CommonFunction';
import TextInput from '../../common/form/TextInput';
import { HelpOutlineOutlined } from '@mui/icons-material';

const brand = JSON.parse(LocalStorage.getItem('branding'))

const Icon = (brand) =>
  brand === 'visa'
    ? VisaIcon
    : brand === 'masterCard'
      ? MastercardIcon
      : brand === 'American Express'
        ? AmexIcon
        : brand === 'Discover'
          ? DiscoverIcon
          : null;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "0px" }}>
          <Typography style={{ width: "100%", padding: "0px", backgroundColor: "transparent", borderRadius: "10px", boxShadow: "none" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Subscription(props) {
  const navigate = useNavigate();
  const whiteLabel = useSelector((state) => state.User?.branding);
  const { userInfo: { user } = {} } = props;

  const { generateDealerCode, data } = useGenerateDealerCode(onSuccess);
  const { lockDealerCode, isLockingCode } = useLockDealerCode(onSuccessLock);
  const { profile, refetch, isFetched } = useGetProfile();
  const { vendorSubscriptionProducts } = useGetVendorSubscriptionProducts();
  const { reactivateVendorSubscription, isReactivatingSubscription } =
    useReactivateVendorSubscription(onSuccessRestart);
  const { reactivateChildSubscription, isReactivatingChildSubscription } =
    useReactivateChildSubscription(onSuccessChildRestart);
  const {
    vendorSubscriptionDetails,
    isFetchedVendorSubscriptionDetails,
    refetchVendorSubscriptionDetails
  } = useGetVendorSubscriptionDetails();
  const { cancelVendorSubscription, isCancellingSubscription } =
    useCancelVendorSubscription(onSuccessCancel);
  const { cancelChildSubscription, isCancellingChildSubscription } =
    useCancelChildSubscription(onSuccessChildCancel);
  const { paymentMethods, isLoading, refetchPaymentMethods } = useGetPaymentMethods();
  const { trialSubscriptionSettings } = useGetTrialSubscriptionSettings();
  const { createVendorSubscription, isCreatingSubscription } = useCreateVendorSubscription(
    onSuccessSubscription,
    onErrorSubscription
  );
  const { createVendorChildSubscription, isCreatingChildSubscription } = useCreateVendorChildSubscription(
    onSuccessChildSubscription,
    onErrorChildSubscription
  );
  const { addPaymentMethod, isAddingPaymentMethod, isError, error } =
    useAddPaymentMethod(onSuccessAddCard);

  const [header, setHeader] = useState('subscription')
  const [value, setValue] = useState(0)
  const [showBack, setShowBack] = useState(false)
  const [code, setCode] = useState('');
  const [isYearly, setIsYearly] = useState({ parent: false, child: false });
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const [showLockModal, setShowLockModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showChangeSubscription, setShowChangeSubscription] = useState(false);
  const [product, setProduct] = useState([])
  const [vedorData, setVendorData] = useState({})
  const [showPayment, setShowPayment] = useState(false)
  const [cardValues, setCardValues] = useState({
    card_holder: '',
    number: '',
    expiration_date: '',
    cvc: ''
  })
  const [selectedId, setSelectedId] = useState('')
  const [payForAllChildAcount, setPayForAllChildAccount] = useState(false)
  const [showActivateSubscriptionModal, setShowActivateSubscriptionModal] = useState(false)
  const [successSubscription, setSuccessSubscription] = useState({
    status: false,
    data: false
  })
  const [selectedCard, setSelectedCard] = useState({})
  const [coupon, setCoupon] = useState('')
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const isLocked = isFetched && profile?.dealer_code && profile?.is_dealer_code_locked;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleCloseSubscription = () => {
    setSuccessSubscription({
      status: false,
      data: false
    })
    getVendorAccount()
    refetchVendorSubscriptionDetails()
  }

  function onSuccessSubscription(data) {
    setShowPayment(false)
    getVendorAccount()
    Userpilot.track('Subscription active')
    refetchVendorSubscriptionDetails()
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully subscribed'
    });
    let perChildAccountAmount = 0;
    let childAccount;
    if (value != 0) {
      childAccount = product?.filter(data => data?.name === 'white label child account');
      perChildAccountAmount = 0;
      if (childAccount?.length > 0) {
        perChildAccountAmount = data?.subscription?.discount?.id ? childAccount[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price * (data?.subscription?.discount?.coupon?.percent_off/100): childAccount[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price
      }
    }
    setSuccessSubscription({
      status: true,
      data: {
        name: value === 0 && user?.role !== 'Child Account' ? 'Dealership Master Account' : 'Rooftop Location Sub Account ',
        amount: value === 0 ? data?.subscription?.discount?.id ? data?.plan?.amount * (data?.subscription?.discount?.coupon?.percent_off/100) :  data?.plan?.amount : perChildAccountAmount,
        interval: data?.plan?.interval || '',
        paid: data?.subscription?.created || '',
        noOfChildAccount: value === 0 ? 0 : vedorData?.active_child_accounts?.length
      }
    })
    setPaymentLoading(false)
  }

  function onErrorSubscription(data) {
    messageManager({
      type: MESSAGE_TYPE.error,
      messageShow: data?.error
    });
    setPaymentLoading(false)
  }

  function onSuccessChildSubscription(data) {
    setShowPayment(false)
    getVendorAccount()
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully subscribed'
    });
    setSelectedId()
    setShowActivateSubscriptionModal(false)
    setPaymentLoading(false)
  }

  function onErrorChildSubscription(data) {
    setPaymentLoading(false)
  }

  function onSuccessAddCard(data) {
    refetchPaymentMethods()
  }

  async function getProducts() {
    let { data } = await APIManager.getInstance().getSubscitptionProducts();
    setProduct(data)
  }

  const handleCoupon = ({target}) => {
    setCoupon(target.value)
  }

  async function getVendorAccount() {
    try {
      let { data } = await APIManager.getInstance().getVendorSubscitption();
      setVendorData(data)
      setShowPayment(false)
      if (user?.role === "Child Account") {
        if (data?.subscription?.plan?.interval === 'year') {
          setHeader("yearlySubscriptionEnrollement")
        } else {
          setHeader("monthlySubscriptionEnrollement")
        }
      } else {
        setHeader('subscription')
      }
      setLoading(false)
      setShowBack(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handlePurchase = () => {
    if (paymentMethods?.length === 0) {
      navigate('./payment/add-card')
    } else {
      setShowPayment(true)
      if (user?.role === "Child Account") {
        setHeader("monthlySubscriptionConfirmation")
      } else {
        setHeader('masterAccountSubscriptionConfirmation')
      }
      setHeader('')
      setShowBack(true)
    }
  }


  React.useEffect(() => {
    document.title = 'Subscription';
  }, []);

  useEffect(() => {
    getProducts()
    getVendorAccount()
  }, [])


  useEffect(() => {
    handleSetCode();
  }, [profile]);

  const handleSetCode = () => {
    if (profile?.dealer_code) {
      setCode(profile?.dealer_code);
    }
    if (profile?.id && !profile?.dealer_code) {
      handleGenerate();
    }
  };



  function onSuccessChildCancel(data) {
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully unsubscribed'
    });
    closeCancelConfirm();
    refetchVendorSubscriptionDetails();
    getVendorAccount()
  }

  const handleGenerate = () => {
    generateDealerCode();
  };

  const handleLockCode = () => {
    lockDealerCode({ dealer_code: code, is_dealer_code_locked: true });
  };

  const handleSaveCode = (code) => {
    lockDealerCode({ dealer_code: code, is_dealer_code_locked: false });
  };

  const handleUnsubscribe = () => {
    if (selectedId) {
      cancelChildSubscription({ cancel_at_period_end: true, ...(selectedId ? { child_account_id: selectedId } : {}) })
    } else {
      cancelVendorSubscription({ cancel_at_period_end: true });
    }
  };

  const handleResumeMembership = () => {
    if (selectedId) {
      try {
        reactivateChildSubscription({ child_account_id: selectedId })
      } catch (e) {
      }
    } else {
      reactivateVendorSubscription();
    }
  };

  const handleNavigate = (subscription) => {
    navigate('./payment', { state: subscription });
  };

  const onChange = (checked) => {
    setIsYearly(checked);
  };

  const closeActivateSubscription = () => {
    setShowActivateSubscriptionModal(false)
  }

  const showCancelConfirm = () => {
    setShowCancelSubscriptionModal(true);
  };

  const closeCancelConfirm = () => {
    setShowCancelSubscriptionModal(false);
    setSelectedId()
  };

  const showChangeConfirm = () => {
    setShowChangeSubscription(true);
  };

  const closeChangeConfirm = () => {
    setShowChangeSubscription(false);
  };

  const showReactivateConfirm = () => {
    setShowReactivateModal(true);
  };

  const closeReactivateConfirm = () => {
    setShowReactivateModal(false);
    setSelectedId()
  };

  const closeLockConfirm = () => {
    setShowLockModal(false);
  };

  const showLockConfirm = () => {
    setShowLockModal(true);
  };

  function onSuccess(data) {
    setCode(data?.code);
    handleSaveCode(data?.code);

    refetch();
  }

  function onSuccessLock(data) {
    refetch();
    closeLockConfirm();
  }

  function onSuccessCancel(data) {
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully unsubscribed'
    });
    closeCancelConfirm();
    refetchVendorSubscriptionDetails();
  }

  function onSuccessRestart(data) {
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully subscribed'
    });
    closeReactivateConfirm();
    getVendorAccount();
  }

  function onSuccessChildRestart(data) {
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully subscribed'
    });
    closeReactivateConfirm();
    getVendorAccount();
  }

  const handleCancelSelectChild = (id) => {
    setSelectedId(id)
    setShowCancelSubscriptionModal(true)
  }

  const handleSubscribeChild = (id) => {
    setSelectedId(id)
    setShowActivateSubscriptionModal(true)
  }

  const handleResumeSubscribeChild = (id) => {
    setSelectedId(id)
    setShowReactivateModal(true)
  }

  const isReady = isFetchedVendorSubscriptionDetails && isFetched;
  let amount = 0;
  let id = 0
  if (user?.role === "Child Account" && !isYearly.child) {
    amount = product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price
    id = product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.id
  } else if (user?.role === "Child Account" && isYearly.child) {
    amount = product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.price
    id = product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.id
  } else if (value === 0) {
    amount = isYearly.parent ? product?.filter(data => data?.name === 'white label account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.price :
      product?.filter(data => data?.name === 'white label account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price
    id = isYearly.parent ? product?.filter(data => data?.name === 'white label account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.id :
      product?.filter(data => data?.name === 'white label account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.id
  } else {
    amount = isYearly.child ? product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.price * (vedorData?.active_child_accounts?.length || 1) :
      product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price * (vedorData?.active_child_accounts?.length || 1)
    id = isYearly.child ? product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.id :
      product?.filter(data => data?.name === 'white label child account')[0]?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.id
  }

  const handlePay = () => {
    setPaymentLoading(true)
    if (selectedId) {
      createVendorChildSubscription({
        child_account_id: selectedId,
        payment_method: selectedCard?.djstripe_id,
        price: id,
        is_trial: false,
        ...(coupon ? { coupon_code: coupon } : {}),
      })
    } else {
      createVendorSubscription({
        payment_method: selectedCard?.djstripe_id,
        price: id,
        is_trial: false,
        ...(coupon ? { coupon_code: coupon } : {}),
        ...(value === 1 ? { pay_for_all_child_accounts: payForAllChildAcount } : {})
      });
    }
  };

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      let defCard = paymentMethods?.filter(e => vendorSubscriptionDetails?.default_payment_method?.djstripe_id === e.djstripe_id || e.is_default)?.[0]
      setSelectedCard(defCard || paymentMethods?.[0] || {})
    }
  }, [paymentMethods, vendorSubscriptionDetails])
  const handleActivateSubscription = () => {
      setShowPayment(true)
      if (user?.role === "Child Account") {
        setHeader("monthlySubscriptionConfirmation")
      } else {
        setHeader('masterAccountSubscriptionConfirmation')
      }
      setHeader('')
      setShowBack(true)
      closeActivateSubscription()
  }

  const handleCardValues = (name, { target: { value } }) => {
    setCardValues({
      ...cardValues,
      [name]: value
    })
  }

  const handleAddCard = () => {
    let date = cardValues.expiration_date
    const card = {
      number: Number(cardValues.number.replace(/\s/g, '')),
      exp_month: date.split("/")[0],
      exp_year: Number(date.split("/")[1]),
      month_exp: date.split("/")[0],
      year_exp: Number(date.split("/")[1]),
      cvc: Number(cardValues.cvc),
      card_holder: cardValues.card_holder,
      type: 'C'
    };
    addPaymentMethod(card)
  }
  const account_information = [

    {
      title: translate('location'),
      dataIndex: 'location',
      align: 'left',
      key: 'location',
      render: (text, row) => row?.child_account?.location_name || ''
    },
    {
      title: translate('adminEmail'),
      dataIndex: 'email',
      align: 'left',
      key: 'email',
      render: (order, row) => {
        return row?.child_account?.admin_email_1 || ''
      }
    },
    {
      title: translate('userRole'),
      dataIndex: 'role',
      align: 'left',
      key: 'role',
      render: (text, row) => row?.child_account?.role || ''
    },
    {
      title: translate('dateCreated'),
      dataIndex: 'created',
      align: 'left',
      key: 'created',
      render: (text, row) => {
        return row?.subscription?.created ? moment(row?.subscription?.created, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm') : '';
      }
    },
    {
      title: translate('actions'),
      dataIndex: '',
      align: 'left',
      key: '',
      render: (value, row) => {

        return (
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              cursor: "pointer"
            }}
            onClick={() => {
              if (!row?.subscription) {
                handleSubscribeChild(row?.child_account?.id)
              } else if (row?.subscription?.cancel_at_period_end) {
                handleResumeSubscribeChild(row?.child_account?.id)
              } else {
                handleCancelSelectChild(row?.child_account?.id)
              }
            }}
          >
            {!row?.subscription ? <p style={{ color: "#23a30e" }}>{translate('activateSubscription')}</p> : row?.subscription?.cancel_at_period_end ? <p style={{ color: "#ffa500" }}>{translate('resumeSubscription')}</p> : <p style={{ color: "#FF0000" }}>{translate('cancelSubscription')}</p>}
          </div>
        );
      }
    }
  ];

  return (
    <div style={{ backgroundColor: "#fff" }} className={css(styles.container)}>
      <div className="flex-row-center">
        {
          showBack && <div
            style={{ marginRight: convertPxToRem(33) }}
            className="flex-row-center-center cursor-pointer"
            onClick={() => {
              setShowPayment(false)
              setShowBack(false)
              setHeader('subscription')
            }}
          >
            <SVGImageComponentReact.IconArrowBack />
          </div>
        }
        <TextBase
          style={{ textTransform: "capitalize", display: "flex", width: "100%", justifyContent: user?.role === "Child Account" ? "center" : "start" }}
          fontWeight={600}
          size={convertPxToRem(24)}
          color={Colors.titlePage}>
          {header}
        </TextBase>
      </div>
      {
        user?.role === "Child Account" ? <>
          {
            showPayment ? <>
              {
                paymentMethods?.length === 0 ? <>
                  <div style={{ width: '342px' }}>
                    <NewCardButton onClick={() => navigate('./payment/add-card')} />
                  </div>
                </> : <>
                  <Paper
                    style={{
                      padding: "20px",
                      width: "60%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      boxShadow: "0px 1px 2px 1px #0000000D",
                      marginTop: "30px"
                    }}
                  >
                    <SubscriptionHeader
                      showChange
                      leftTitle="childAccountEnrollement"
                      subscription={vendorSubscriptionDetails}
                      onClickRight={showCancelConfirm}
                      onClickLeft={showChangeConfirm}
                      onReactivate={showReactivateConfirm}
                      isLoading={isCancellingSubscription}
                    />
                    <ActiveSubscription
                      subscription={vendorSubscriptionDetails}
                      status="paymentDue"
                    />
                  </Paper>
                  <Paper
                    style={{
                      padding: "20px",
                      width: "60%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "30px",
                      boxShadow: "0px 1px 2px 1px #0000000D",
                    }}
                  >
                    <SubscriptionHeader
                      leftTitle="savedCard"
                      btnText="edit"
                      onClickRight={() => navigate('./payment')}
                    />
                    {paymentMethods?.map((card) => {
                      const cardDetails = card?.card;

                      return (
                        <div
                          style={{ marginTop: '20px', width: '310px', marginRight: '20px' }}
                          onClick={() => { setSelectedCard(card || {}) }}>
                          <CreditCard
                            selected={selectedCard?.djstripe_id === card?.djstripe_id}
                            values={{
                              ...card?.card,
                              number: '**** **** **** ' + cardDetails?.last4,
                              month_exp: cardDetails?.exp_month,
                              year_exp: cardDetails?.exp_year,
                              brand: getCreditCardTypeString(cardDetails?.brand),
                              card_holder: card?.billing_details?.name
                            }}
                          />
                        </div>
                      );
                    })}
                    <TextInput 
                      label={translate('applyCoupon')}
                      style={{
                        width: "100%",
                        paddingTop: "10px",
                        height: "35px"
                      }}
                      onChange={handleCoupon}
                      value={coupon}
                    />
                    <ButtonGradient
                      style={{
                        marginTop: "50px",
                        width: "100%"
                      }}
                      loading={isCreatingSubscription||paymentLoading}
                      title={`${translate('proceedToPay')} $${amount}`}
                      onClick={handlePay}
                    />
                  </Paper>
                </>
              }
            </> : vedorData?.djstripe_subscription ? <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '8px'
                  }}>
                  <SubscriptionHeader
                    showChange
                    leftTitle="dealerships_businesses"
                    subscription={vendorSubscriptionDetails}
                    onClickRight={showCancelConfirm}
                    onClickLeft={showChangeConfirm}
                    onReactivate={showReactivateConfirm}
                    isLoading={isCancellingSubscription}
                  />
                  <ActiveSubscription
                    subscription={vendorSubscriptionDetails}
                    isParentSubscription={vedorData?.is_different_user}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '8px'
                  }}>
                  <SubscriptionHeader
                    leftTitle="savedCard"
                    btnText="edit"
                    onClickRight={() => navigate('./payment')}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      maxWidth: '700px'
                    }}>
                    {paymentMethods?.length === 0 ? (
                      <div style={{ width: '342px' }}>
                        <NewCardButton onClick={() => navigate('./payment/add-card')} />
                      </div>
                    ) : null}

                    {paymentMethods?.map((card) => {
                      const cardDetails = card?.card;

                      return (
                        <div
                          style={{ marginTop: '20px', width: '310px', marginRight: '20px' }}
                          onClick={() => { setSelectedCard(card || {}) }}>
                          <CreditCard
                            selected={selectedCard?.djstripe_id === card?.djstripe_id}
                            values={{
                              ...card?.card,
                              number: '**** **** **** ' + cardDetails?.last4,
                              month_exp: cardDetails?.exp_month,
                              year_exp: cardDetails?.exp_year,
                              brand: getCreditCardTypeString(cardDetails?.brand),
                              card_holder: card?.billing_details?.name
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
            : loading ? <CircularProgress /> : 
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                  margin: "30px auto 0 auto"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  {
                    product?.filter(data => data?.name === 'white label child account').map(data => (
                      <SubscriptionCard
                          isYearly={isYearly.child}
                          title={'childAccounts'}
                          subscription={{
                            price: isYearly.child ? data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.price : data?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price,
                            recurring_interval: isYearly.child ? 'year' : 'month',
                            discount: isYearly.child ? data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.discount : data?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.discount
                          }}
                          terms={[
                            'customizationAndBranding'
                          ]}
                          subContent={<>{translate('continueSale')} <br />{translate('beCharged')} <span style={{ color: "#F8B218" }}>${amount}/{translate('monthly')}</span></>}
                          showEmpty={data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly' || e?.name?.toLowerCase() === 'monthly').length <= 1}
                          pricelist={data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly' || e?.name?.toLowerCase() === 'monthly')}
                          onChange={() => setIsYearly({ ...isYearly, child: !isYearly.child })}
                          onPurchase={handlePurchase}
                        />
                    ))
                  }
                </div>
              </div>
          }

        </> :
          showPayment ? <>
            {
              paymentMethods?.length === 0 ? <>
                <div style={{ width: '342px' }}>
                  <NewCardButton onClick={() => navigate('./payment/add-card')} />
                </div>
              </> : <>
                <Paper
                  style={{
                    padding: "20px",
                    width: "60%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    boxShadow: "0px 1px 2px 1px #0000000D",
                    marginTop: "30px"
                  }}
                >
                  <SubscriptionHeader
                    showChange
                    leftTitle="dealershipMasterAccountEnrollement"
                    subscription={vendorSubscriptionDetails}
                    onClickRight={showCancelConfirm}
                    onClickLeft={showChangeConfirm}
                    onReactivate={showReactivateConfirm}
                    isLoading={isCancellingSubscription}
                  />
                  <ActiveSubscription
                    subscription={vendorSubscriptionDetails}
                    status="paymentDue"
                  />
                </Paper>
                <Paper
                  style={{
                    padding: "20px",
                    width: "60%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "30px",
                    boxShadow: "0px 1px 2px 1px #0000000D",
                  }}
                >
                  <SubscriptionHeader
                    leftTitle="savedCard"
                    btnText="edit"
                    onClickRight={() => navigate('./payment')}
                  />
                  {paymentMethods?.map((card) => {
                    const cardDetails = card?.card;

                    return (
                      <div
                        style={{ marginTop: '20px', width: '310px', marginRight: '20px' }}
                        onClick={() => { setSelectedCard(card || {}) }}
                      >
                        <CreditCard
                          selected={selectedCard?.djstripe_id === card?.djstripe_id}
                          values={{
                            ...card?.card,
                            number: '**** **** **** ' + cardDetails?.last4,
                            month_exp: cardDetails?.exp_month,
                            year_exp: cardDetails?.exp_year,
                            brand: getCreditCardTypeString(cardDetails?.brand),
                            card_holder: card?.billing_details?.name
                          }}
                        />
                      </div>
                    );
                  })}
                  <TextInput 
                    label={translate('applyCoupon')}
                    style={{
                      width: "100%",
                      paddingTop: "10px",
                      height: "35px"
                    }}
                    onChange={handleCoupon}
                    value={coupon}
                  />
                  <ButtonGradient
                    style={{
                      marginTop: "50px",
                      width: "100%"
                    }}
                    title={`${translate('proceedToPay')} $${amount}`}
                    onClick={handlePay}
                    loading={paymentLoading}
                  />
                </Paper>
              </>

            }
          </> :
            <div
              style={{
                display: 'flex',
                marginTop: "20px",
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
            >
              <Box style={{ width: "100%" }} sx={{}}>
                <Tabs TabIndicatorProps={{ style: { background: '#F8B218', textTransform: "capitalize" } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab TabIndicatorProps={{ style: { background: '#F8B218', textTransform: "capitalize" } }} style={{ borderBottom: value === 0 ? "1px solid #F8B218" : "1px solid #C8C8C8", color: value === 0 ? "#F8B218" : "#C8C8C8", fontSize: "16px", fontWeight: "500", lineHeight: '21px', textTransform: "capitalize" }} label={translate("masterAccountPurchase")} {...a11yProps(0)} />
                  <Tab TabIndicatorProps={{ style: { background: '#F8B218', textTransform: "capitalize" } }} style={{ borderBottom: value === 1 ? "1px solid #F8B218" : "1px solid #C8C8C8", color: value === 1 ? "#F8B218" : "#C8C8C8", fontSize: "16px", fontWeight: "500", lineHeight: '21px', textTransform: "capitalize" }} label={translate("rooftopPurchase")} {...a11yProps(1)} />
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                  {
                    loading ? <CircularProgress /> : 
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "60px"
                      }}
                    >
                      {
                        !vedorData?.djstripe_subscription ? product?.filter(data => data?.name === 'white label account').map(data => (
                          <SubscriptionCard
                            isYearly={isYearly.parent}
                            title={'dealershipMasterAccount'}
                            subscription={{
                              price: isYearly.parent ? data?.prices?.filter(e => e?.name === 'Yearly - white label account')[0]?.price : data?.prices?.filter(e => e?.name === 'Monthly - white label account')[0]?.price,
                              recurring_interval: isYearly.parent ? 'year' : 'month',
                              discount: isYearly.parent ? data?.prices?.filter(e => e?.name === 'Yearly - white label account')[0]?.discount : data?.prices?.filter(e => e?.name === 'Monthly - white label account')[0]?.discount
                            }}
                            terms={[
                              'term1',
                              'term2',
                              'term3'
                            ]}
                            showEmpty={data?.prices?.filter(e => e?.name === 'Yearly - white label account' || e?.name === 'Monthly - white label account').length <= 1}
                            pricelist={data?.prices?.filter(e => e?.name === 'Yearly - white label account' || e?.name === 'Monthly - white label account')}
                            onChange={() => setIsYearly({ ...isYearly, parent: !isYearly.parent })}
                            onPurchase={handlePurchase}
                          />
                        )) : <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '20px',
                                marginBottom: '20px',
                                borderRadius: '8px'
                              }}>
                              <SubscriptionHeader
                                showChange
                                leftTitle="dealerships_businesses"
                                subscription={vendorSubscriptionDetails}
                                onClickRight={showCancelConfirm}
                                onClickLeft={showChangeConfirm}
                                onReactivate={showReactivateConfirm}
                                onActivate={handlePurchase}
                                isLoading={isCancellingSubscription}
                              />
                              <ActiveSubscription
                                subscription={vendorSubscriptionDetails}
                              />
                            </div>
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '20px',
                                marginBottom: '20px',
                                borderRadius: '8px'
                              }}>
                              <SubscriptionHeader
                                leftTitle="savedCard"
                                btnText="edit"
                                onClickRight={() => navigate('./payment')}
                              />

                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  maxWidth: '700px'
                                }}>
                                {paymentMethods?.length === 0 ? (
                                  <div style={{ width: '342px' }}>
                                    <NewCardButton onClick={() => navigate('./payment/add-card')} />
                                  </div>
                                ) : null}

                                {paymentMethods?.map((card) => {
                                  const cardDetails = card?.card;

                                  return (
                                    <div
                                      style={{ marginTop: '20px', width: '310px', marginRight: '20px' }}
                                      onClick={() => { setSelectedCard(card || {}) }}>
                                      <CreditCard
                                        selected={selectedCard?.djstripe_id === card?.djstripe_id}
                                        values={{
                                          ...card?.card,
                                          number: '**** **** **** ' + cardDetails?.last4,
                                          month_exp: cardDetails?.exp_month,
                                          year_exp: cardDetails?.exp_year,
                                          brand: getCreditCardTypeString(cardDetails?.brand),
                                          card_holder: card?.billing_details?.name
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {
                    loading ? <CircularProgress /> :
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "60px"
                      }}
                    >
                      {
                        vedorData?.active_child_accounts?.filter(e => e?.subscription?.djstripe_id).length === 0 && <div
                          style={{
                            display: 'flex',
                            justifyContent: "space-between",
                            padding: "5px 15px",
                            borderRadius: "6px",
                            backgroundColor: "#FBF4E6",
                            width: "70%",
                            marginBottom: "30px",
                            alignItems: "center"
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#4F4F4F"
                            }}
                          >
                            {translate('payForChildAccounts')}
                          </span>
                          <div className="react-switch" style={{ margin: '0px', display: "flex" }}>
                            <Switch
                              checked={payForAllChildAcount}
                              onColor={Colors.primary}
                              offColor="#E5E7EB"
                              checkedHandleIcon={<div />}
                              inputMode="none"
                              onChange={() => setPayForAllChildAccount(!payForAllChildAcount)}
                              uncheckedIcon={<div />}
                              checkedIcon={<div />}
                              style={{ margin: "0px 10px" }}
                              size={'small'}
                            />
                          </div>
                        </div>
                      }
                      {
                        vedorData?.active_child_accounts?.filter(e => e?.subscription?.djstripe_id).length > 0 ? <>
                          <Paper
                            style={{
                              width: "100%",
                              padding: "30px",
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #C8C8C8",
                                padding: "10px"
                              }}
                            >
                              <div
                                style={{
                                  width: "30%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000"
                                    }}
                                  >
                                    {translate('rooftopPurchase')}
                                  </div>
                                  <span
                                    style={{
                                      padding: "7px 20px",
                                      backgroundColor: "#C1FFDE",
                                      borderRadius: "12px",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      color: "#23A30E",
                                      marginLeft: "10%",
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {vedorData?.status}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    color: "#FBB516",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    lineHeight: "17px"
                                  }}
                                >
                                  {formatCurrency(amount)} / {translate('perMonth')}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "24px"
                                  }}
                                >
                                  {translate('renewDate')}  {moment(vedorData?.subscription?.current_period_end).add(1, 'days').format('MM/DD/YYYY')}
                                </span>
                                <div
                                  style={{
                                    padding: "5px 8px",
                                    backgroundColor: "#FC0101",
                                    color: "#fff",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    width: "fit-content",
                                    marginTop: "15px"
                                  }}
                                >
                                  <AccessTimeIcon
                                    style={{
                                      color: "#fff",
                                      marginRight: "5px",
                                      fontSize: "14px"
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      lineHeight: "21px"
                                    }}
                                  >
                                    {translate('expiredIn')} : {moment(vedorData?.subscription?.current_period_end).diff(moment(), 'days')} {translate('days')}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "30%",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <TextBase
                                  fontWeight={500}
                                  size={convertPxToRem(14)}
                                  color={Colors.black}
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  payForChildAccounts
                                  <span>
                                    <Tooltip title={translate('payForChildAccountsTooltip')} placement="top">
                                      <HelpOutlineOutlined style={{ color: "#C8C8C8", fontSize: "16px", marginLeft: "5px" }} />
                                    </Tooltip>
                                  </span>
                                </TextBase>
                                <div className="react-switch" style={{ margin: '0px 20px' }}>
                                  <Switch
                                    checked={vedorData?.pay_for_all_child_accounts}
                                    onColor={Colors.primary}
                                    offColor="#E5E7EB"
                                    checkedHandleIcon={<div />}
                                    inputMode="none"
                                    uncheckedIcon={<div />}
                                    checkedIcon={<div />}
                                    style={{ margin: "0px 10px" }}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px"
                              }}
                            >
                              <div
                                style={{
                                  width: "30%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000"
                                    }}
                                  >
                                    {translate('billingAddress')}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "#4F4F4F",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    textAlign: "left",
                                    marginTop: "15px"
                                  }}
                                >
                                  {vedorData?.default_payment_method?.billing_details?.line1} <br />
                                  {vedorData?.default_payment_method?.billing_details?.line2} <br />
                                  {vedorData?.default_payment_method?.billing_details?.city} <br />
                                  {vedorData?.default_payment_method?.billing_details?.state} <br />
                                  {vedorData?.default_payment_method?.billing_details?.country} - {vedorData?.default_payment_method?.billing_details?.postal_code} <br />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "30%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000"
                                    }}
                                  >
                                    {translate('paymentMethod')}
                                  </div>
                                  <span
                                    style={{
                                      marginLeft: "10%",
                                      color: "#F8B218",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => navigate('./payment/add-card')}
                                  >
                                    {translate('addCard')}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    marginTop: "15px",
                                    display: "flex",
                                    width: "100%"
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 20px",
                                      backgroundColor: "#FFF7E6",
                                      borderRadius: "4px",
                                      display: "flex"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "30%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      <img src={Icon(vedorData?.default_payment_method?.card?.brand)} />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "70%",
                                        flexDirection: "column",
                                        marginLeft: "10px"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "12px",
                                          color: "#6B7280"
                                        }}
                                      >
                                        {translate('debitCard')}
                                      </span>
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "12px",
                                          color: "#404040"
                                        }}
                                      >
                                        * * * {vedorData?.default_payment_method?.card?.last4}
                                      </span>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div
                                style={{
                                  width: "30%",
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px"
                              }}
                            >
                              <Table
                                style={{ width: '100%' }}
                                columns={account_information}
                                components={{
                                  header: {
                                    cell: ({ children, ...rest }) => {
                                      return (
                                        <th
                                          {...rest}
                                          style={{
                                            padding: '23px',
                                          }}
                                        >
                                          {children}
                                        </th>
                                      );
                                    }
                                  }
                              }}
                                dataSource={vedorData?.active_child_accounts || []}
                                className="table"
                                bordered={false}
                                pagination={false}
                              />
                            </div>
                          </Paper>
                        </> :

                          product?.filter(data => data?.name === 'white label child account').map(data => (
                            <SubscriptionCard
                              isYearly={isYearly.child}
                              title={'Rooftop Location Sub Account'}
                              subTitle={isYearly.child ? 'Yearly Subscription' : 'Monthly Subscription'}
                              subscription={{
                                price: isYearly.child ? data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.price : data?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.price,
                                recurring_interval: isYearly.child ? 'year' : 'month',
                                discount: isYearly.child ? data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly')[0]?.discount : data?.prices?.filter(e => e?.name?.toLowerCase() === 'monthly')[0]?.discount
                              }}
                              terms={[
                                'Sub account for master account for managing individual rooftop locations',
                                'A full featured account with custom branding andcustom domain capability',
                              ]}
                              pricelist={data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly' || e?.name?.toLowerCase() === 'monthly')}
                              onChange={() => setIsYearly({ ...isYearly, child: !isYearly.child })}
                              childAccount={vedorData?.active_child_accounts?.length}
                              payForAllChildAcount={payForAllChildAcount}
                              onPurchase={handlePurchase}
                              showEmpty={data?.prices?.filter(e => e?.name?.toLowerCase() === 'yearly' || e?.name?.toLowerCase() === 'monthly').length <= 1}
                            />
                          ))

                      }
                    </div>
                  }
                </CustomTabPanel>
              </Box>
            </div>
      }


      <ConfirmModal
        visible={showChangeSubscription}
        status="warning"
        okBtnText="KEEP SUBSCRIPTION"
        cancelBtnText="CHANGE SUBSCRIPTION"
        width="600px"
        handleOk={closeChangeConfirm}
        onClose={closeChangeConfirm}
        handleCancel={closeChangeConfirm}>
        <TextBase
          style={{ fontSize: '24px', textAlign: 'center' }}
          fontWeight={400}
          size={convertPxToRem(14)}
          color={Colors.black}>
          subscriptionConformation
        </TextBase>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0px'
          }}>
          <TextBase
            style={{ fontSize: '14px', textAlign: 'center' }}
            fontWeight={400}
            size={convertPxToRem(14)}
            color={Colors.grey}>
            subscriptionStart{': '}
          </TextBase>

          <TextBase
            style={{ textAlign: 'center', marginLeft: '6px' }}
            fontWeight={400}
            size={convertPxToRem(14)}
            color={Colors.black}>
            {dayjs(vendorSubscriptionDetails?.subscription?.current_period_end).format(
              'MM/DD/YYYY'
            )}
          </TextBase>
        </div>
      </ConfirmModal>

      <ConfirmModal
        visible={showLockModal}
        status="success"
        okBtnText="LOCK CODE"
        cancelBtnText="CANCEL"
        width="450px"
        hideIcon
        showIcon
        message=""
        fontWeight={600}
        handleOk={handleLockCode}
        handleCancel={closeLockConfirm}
        onClose={closeLockConfirm}
        isLoading={isLockingCode}>
        <TextBase
          style={{ textAlign: 'center', marginBottom: '20px' }}
          fontWeight={600}
          size={convertPxToRem(24)}
          color={Colors.black}>
          lockDealerCode{' '}
        </TextBase>

        <TextBase
          style={{ textAlign: 'center', marginBottom: '50px' }}
          fontWeight={400}
          size={convertPxToRem(14)}
          color={Colors.black}>
          codeWarning{' '}
        </TextBase>
      </ConfirmModal>

      <ConfirmModal
        visible={showReactivateModal}
        status="success"
        okBtnText="RESUME"
        cancelBtnText="CANCEL"
        width="450px"
        hideIcon
        showIcon
        message=""
        fontWeight={600}
        handleOk={handleResumeMembership}
        handleCancel={closeReactivateConfirm}
        onClose={closeReactivateConfirm}
        isLoading={selectedId ? isReactivatingChildSubscription : isReactivatingSubscription}>
        <TextBase
          style={{ textAlign: 'center', marginBottom: '20px' }}
          fontWeight={600}
          size={convertPxToRem(24)}
          color={Colors.black}>
          resumeSubscription
        </TextBase>

        <TextBase
          style={{ textAlign: 'center', marginBottom: '50px' }}
          fontWeight={400}
          size={convertPxToRem(14)}
          color={Colors.black}>
          resumeSubscriptionConformation
        </TextBase>
      </ConfirmModal>

      <ConfirmModal
        visible={showCancelSubscriptionModal}
        status="warning"
        okBtnText="keepSubscription"
        cancelBtnText="cancelSubscription"
        width="600px"
        handleOk={closeCancelConfirm}
        handleCancel={handleUnsubscribe}
        onClose={closeCancelConfirm}
        isLoadingLeft={selectedId ? isCancellingChildSubscription : isCancellingSubscription}>
        <TextBase
          style={{ fontSize: '24px', textAlign: 'center', }}
          fontWeight={400}
          size={convertPxToRem(14)}
          color={Colors.black}>
          cancelSubscriptionConformation
        </TextBase>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0px',
          }}>
          <TextBase
            style={{ fontSize: '14px', textAlign: 'center' }}
            fontWeight={400}
            size={convertPxToRem(14)}
            color={Colors.grey}>
            subscriptionEnd{' '}
          </TextBase>

          <TextBase
            style={{ textAlign: 'center', marginLeft: '6px' }}
            fontWeight={400}
            size={convertPxToRem(14)}
            color={Colors.black}>
            {dayjs(vendorSubscriptionDetails?.subscription?.current_period_end).format(
              'MM/DD/YYYY'
            )}
          </TextBase>
        </div>
      </ConfirmModal>

      <ConfirmModal
        visible={showActivateSubscriptionModal}
        status="warning"
        okBtnText="activateSubscription"
        cancelBtnText="closeSubscription"
        width="600px"
        handleOk={handleActivateSubscription}
        handleCancel={closeActivateSubscription}
        onClose={closeActivateSubscription}
        isLoading={isCreatingChildSubscription}>
        <TextBase
          style={{ fontSize: '24px', textAlign: 'center' }}
          fontWeight={400}
          size={convertPxToRem(14)}
          color={Colors.black}>
          confirmActivateSubscription
        </TextBase>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0px'
          }}>
          <TextBase
            style={{ fontSize: '14px', textAlign: 'center' }}
            fontWeight={400}
            size={convertPxToRem(14)}
            color={Colors.grey}>
            subscriptionEnd{' '}
          </TextBase>

          <TextBase
            style={{ textAlign: 'center', marginLeft: '6px' }}
            fontWeight={400}
            size={convertPxToRem(14)}
            color={Colors.black}>
            {dayjs(vendorSubscriptionDetails?.subscription?.current_period_end).format(
              'MM/DD/YYYY'
            )}
          </TextBase>
        </div>
      </ConfirmModal>
      <SuccessModal
        open={successSubscription.status}
        data={successSubscription.data}
        onClose={handleCloseSubscription}
        role={user?.role}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
