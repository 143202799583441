import axios from 'axios';
import ConfigStore from '../redux/store';

const { store } = ConfigStore();

const domain = window.location.hostname != 'localhost' ? window.location.origin : 'https://www.oldeasternshore.com';
const baseUrl = domain + '/';
const apiVersion = 'api/v1/';
export const API_URL = {
    baseUrl: baseUrl,
    whiteLabel: baseUrl + apiVersion + 'white-labels/all/',
    restAuth: baseUrl + apiVersion + 'rest-auth/',
    login: baseUrl + apiVersion + 'accounts/vendor/login/token/',
    resetPassword: baseUrl + apiVersion,
    changePassword: baseUrl + apiVersion,
    setNewPassword: baseUrl + apiVersion,
    modulesPayment: baseUrl + 'modules/payments/',
    profile: baseUrl + apiVersion + 'accounts/user-profile/',
    vendorProfile: baseUrl + apiVersion + 'accounts/profile/vendor/',
    vendorSignup: baseUrl + apiVersion + 'accounts/signup/vendor/',
    userInfo: baseUrl + apiVersion + 'user-financial-info/',
    assets: baseUrl + '/assets/',
    liabilities: baseUrl + '/liabilities/',
    vendors: baseUrl + apiVersion + 'vendors/all/',
    sendSMS: baseUrl + apiVersion + 'accounts/phone-numbers/send-otp/',
    sendSMSUser: baseUrl + apiVersion + 'accounts/phone-numbers/send-otp/',
    verifyPhoneNumber: baseUrl + apiVersion + 'accounts/phone-numbers/verify-otp/',
    verifyPhoneNumberUser: baseUrl + apiVersion + 'accounts/phone-numbers/verify-otp/',
    notifications: baseUrl + apiVersion + 'notifications/',
    notificationUnreadCount: baseUrl + apiVersion + 'unread_count/',
    registerDevice: baseUrl + apiVersion + 'devices/',
    markAsRead: baseUrl + apiVersion + 'notifications/',
    markAsReadAll: baseUrl + apiVersion + 'mark-all-notifications/',
    orders: baseUrl + apiVersion + 'orders/',
    quotes: baseUrl + apiVersion + 'quotes/',
    createChatRoom: baseUrl + apiVersion + 'chats/firestore/order-rooms/create/',
    getFirebaseAuthToken: baseUrl + apiVersion + 'chats/firebase/custom-token/',
    userQuotes: baseUrl + apiVersion + 'user-quotes/',
    incomeAndExpenses: baseUrl + '/income-and-expenses/',
    terms: baseUrl + apiVersion,
    privacy: baseUrl + apiVersion,
    dashboardTotals: baseUrl + apiVersion + 'total/',
    acceptedOrders: baseUrl + apiVersion + 'order-accepted/',
    numberOfQuotes: baseUrl + apiVersion + 'order-summary/',
    inviteTeamMember: baseUrl + apiVersion + 'users/add-team-member/',
    teamMembers: baseUrl + 'api/v1/team-member/',
    themeUpdate: baseUrl + apiVersion + 'white-labels/create/',
    emailTemplateUpdate: baseUrl + apiVersion + 'white-labels/create-email-template/',
    getChildAccounts: baseUrl + apiVersion + 'white-label-partner/child-orders/',
    getParentOrders: baseUrl + apiVersion + 'white-label-partner/parent-orders/',
    getWhitelabelData: baseUrl + apiVersion + 'white-labels/create/',
    getVendorSubscitption: baseUrl + apiVersion + 'subscriptions/vendor/',
    getSubscitptionProducts: baseUrl + apiVersion + 'subscriptions/products/vendor/',
    getDynamicAttributes: baseUrl + apiVersion + 'white-labels/create-order-form/',
    sendSetUpLink: baseUrl + apiVersion + 'white-labels/send-setup-link/',
    updateInprogress: baseUrl + apiVersion + 'order-inprogress/',
    scanner: baseUrl + apiVersion + 'vin_scanner/perform-ocr-and-highlight/',
    searchAnalyticsURL: baseUrl + apiVersion + 'child-account-management/child-accounts/',
    analyticsCards: baseUrl + apiVersion + 'total/',
    acceptServiceURL: baseUrl + apiVersion + 'service_accepted/quote/',
    passwordChange: baseUrl + apiVersion + 'password-change/',
};

export const API_ENDPOINT = {
    api: {
        prefix: apiVersion,
        profile: 'profile',
        userFinancialInfo: 'user-financial-info'
    },
    restAuth: {
        prefix: 'rest-auth/',
        login: 'login/',
        logout: 'logout/',
        resetPassword: 'password/reset/',
        changePassword: 'password/change/',
        resetPasswordConfirm: 'password/reset/confirm/',
        register: 'registration/',
        registerVerifyEmail: 'registration/verify-email/',
        user: 'user/'
    },
    modulesPayments: {
        prefix: 'modules/payments/',
        history: 'get_payments_history/',
        methods: 'get_payments_methods/',
        sheets: 'payment_sheet/'
    },
    incomeAndExpenses: {
        prefix: 'income-and-expenses',
        payStubs: 'pay-stubs/',
        taxReturn: 'income-tax-returns/',
        expenses: 'expenses/'
    },
    vendors: 'vendors/all/',
    quotes: 'quotes/',
    terms: 'terms-and-conditions/',
    privacy: 'privacy-policy/',
    resetPassword: 'reset-password/',
    setNewPassword: 'password-change/',
    changePassword: 'reset-password/change-password/'
};

export const request = axios.create({
    baseURL: baseUrl + apiVersion
});

request.interceptors.request.use(function(config) {
    const token = store.getState()?.User?.userInfo?.token;

    if (token) {
        config.headers.Authorization = 'Token ' + token;
    }

    return config;
});

request.interceptors.response.use(
    (response) => {
        return response;
    },
    async function(error) {
        if (error.response.status === 401) {
            // store.dispatch(logoutAction());
        }
        return Promise.reject(error);
    }
);
