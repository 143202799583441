import LocalStorage from '../../../defined/localStorage'
import { deepCopyObject, getValueFromObjectByKeys, myLog } from '../../../helper'
import { mapPlanKey, userKey } from '../../../utils/defined'
import { NORMAL_TYPE, REQUEST_SUBTYPE, REQUEST_TYPE } from '../../action/ActionType'

const initState = {
    userInfo: {
        plans: [
            {
                key: mapPlanKey.FREE
            }
        ],
        avatar: 'https://cdn.pixabay.com/photo/2012/08/06/00/53/bridge-53769_960_720.jpg',
        [userKey.name]: LocalStorage.getItem(LocalStorage.DEFINE_KEY.USER_NAME) || '',
        [userKey.firstName]: '',
        [userKey.lastName]: '',
        [userKey.emailAddress]: '',
        [userKey.phone]: '',
        [userKey.streetAddress]: '',
        [userKey.city]: '',
        [userKey.state]: '',
        [userKey.zipCode]: '',
        [userKey.isMarried]: false,
        [userKey.isRetirement]: ''
    },
    userDetail: {},
    notification: [],
    vendor: null,
    userCache: null,
    whiteLabel: {},
    notifications: {
        data: [],
        page: { current: 1, has_next: false, has_previous: false, total_records: 0 },
        unread_count: 0,
        unread_vendornote_count: 0
    },
    branding: {},
    order_config: {},
    order_details: {}
}

const UserReducer = (state = initState, action) => {
    switch (action.key) {
        case REQUEST_TYPE.GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    data: action?.data?.data?.data,
                    page: action?.data?.data?.page
                }
            }

        case REQUEST_TYPE.GET_NOTIFICATIONS_APPEND:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    let notificationsData = state.notifications.data.concat(action?.data?.data?.data)
                    let page = action?.data?.data?.page

                    const updateNotifications = {
                        ...state.notifications,
                        data: notificationsData,
                        page
                    }
                    return {
                        ...state,
                        notifications: updateNotifications
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.GET_NOTIFICATIONS_COUNT:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            ...action?.data?.data
                        }
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.UPDATE_USER_NOTIFICATIONS_COUNT:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            ...(action?.data?.data||{})
                        }
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.UPDATE_NOTIFICATIONS_COUNT:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            ...(action?.data?.data||{})
                        }
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.MARK_AS_READ:
            const isVendorNotes = action?.data?.data?.vendor_notes
            let unread_count = state.notifications.unread_count
            let unread_vendornote_count = state.notifications.unread_vendornote_count

            if (isVendorNotes) {
                unread_vendornote_count = unread_vendornote_count - 1
            } else {
                unread_count = unread_count - 1
            }
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            unread_count,
                            unread_vendornote_count,
                        }
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.MARK_AS_READ_ALL:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    // parse notifications data and set is_read key to true for all items when is_read is false
                    let updateNotifications = state.notifications.data.map((item) => {
                        if (!item.is_read) {
                            item.is_read = true
                        }
                        return item
                    })
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            unread_count: 0,
                            data: updateNotifications,

                        }
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.MARK_AS_READ_ALL_VENDORNOTES:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    // parse notifications data and set is_read key to true for all items when is_read is false
                    let updateNotifications = state.notifications.data.map((item) => {
                        if (!item.is_read) {
                            item.is_read = true
                        }
                        return item
                    })
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            unread_vendornote_count: 0,
                            data: updateNotifications,
                        }
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }

        case REQUEST_TYPE.WHITE_LABEL:
            const { data } = action?.data
            const whiteLabel = data?.results?.[0]
            return { ...state, whiteLabel }

        case REQUEST_TYPE.LOGIN:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    const userInfo = getValueFromObjectByKeys(action, ['data', 'data'], {})
                    if (userInfo && userInfo.token) {
                        LocalStorage.setItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN, userInfo.token)
                        LocalStorage.setItem(LocalStorage.DEFINE_KEY.JWT_TOKEN, userInfo?.jwt_token)
                        return userInfo && Object.keys(userInfo).length > 0 ? { ...state, userInfo } : state
                    }
                    return state
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.LOG_OUT:
            return {
                ...state,
                token: null,
                user: {},
                profile: {},
                whiteLabel: {},
                tempToken: null
            }
        case REQUEST_TYPE.GET_PROFILE:
        case REQUEST_TYPE.UPDATE_PROFILE:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    myLog('GET_PROFILE=>', action.data)
                    const profile = getValueFromObjectByKeys(action, ['data', 'data'], {})
                    return profile && Object.keys(profile).length > 0
                        ? {
                            ...state,
                            userInfo: {
                                ...deepCopyObject(state.userInfo),
                                profile: profile
                            }
                        }
                        : state
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.GET_VENDOR:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    const results = getValueFromObjectByKeys(action, ['data', 'data', 'results'], [])
                    return results && results.length
                        ? {
                            ...state,
                            vendor: results[0]
                        }
                        : state
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.UPDATE_VENDOR:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    const dataUpdateVendor = getValueFromObjectByKeys(action, ['data', 'data'], [])
                    return {
                        ...state,
                        userInfo: {
                            ...deepCopyObject(state.userInfo),
                            user: {
                                ...deepCopyObject(state.userInfo.user),
                                vendor: dataUpdateVendor
                            }
                        },
                        vendor: dataUpdateVendor
                    }
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case REQUEST_TYPE.UPDATE_FINANCIAL:
            switch (action.subType) {
                case REQUEST_SUBTYPE.REQUEST:
                    return state
                case REQUEST_SUBTYPE.SUCCESS:
                    myLog('GET_PROFILE=>', action.data)
                    const userFinancialInfo = getValueFromObjectByKeys(action, ['data', 'data'], {})
                    return userFinancialInfo && Object.keys(userFinancialInfo).length > 0
                        ? {
                            ...state,
                            userInfo: {
                                ...deepCopyObject(state.userInfo),
                                user_financial_info: userFinancialInfo
                            }
                        }
                        : state
                case REQUEST_SUBTYPE.ERROR:
                    return state
                default:
                    return state
            }
        case NORMAL_TYPE.SAVE_USER_INFO:
            myLog('SAVE_USER_INFO=>', action.data)
            return { ...state, userInfo: action.data }
        case NORMAL_TYPE.SAVE_USER_CACHE:
            myLog('SAVE_USER_CACHE=>', action.data)
            return { ...state, userCache: action.data }
        case NORMAL_TYPE.SAVE_USER_DETAIL_LOCAL:
            myLog('SAVE_USER_DETAIL_LOCAL=>', action.data)
            return { ...state, userDetail: action.data }
        case NORMAL_TYPE.SAVE_NOTIFICATION:
            myLog('SAVE_NOTIFICATION=>', action.data)
            return { ...state, notification: action.data }
        case REQUEST_TYPE.SAVE_BRANDING:
            if (action?.data?.data) {
                LocalStorage.setItem('branding', JSON.stringify(action?.data?.data || {}))
            }
            return { ...state, branding: action?.data?.data || {} }
        case REQUEST_TYPE.SAVE_ORDER:
            return { ...state, order_details: action?.data || {} }
        case REQUEST_TYPE.SAVE_ORDER_BRAND:
            return { ...state, order_config: action?.data?.data || {} }
        default:
            return state
    }
}

export default UserReducer
