import { createrAction } from '../RActions';
import { NORMAL_TYPE, REQUEST_TYPE } from '../ActionType';
import { getAction, getActionAPI } from '../UtilAction';
import APIManager from '../../../connection/ApiManager';
import { myLog } from '../../../helper';
import Utils from '../../../utils'

const {
    WHITE_LABEL,
    LOGIN,
    LOG_OUT,
    REGISTER,
    UPDATE_FINANCIAL,
    UPDATE_PROFILE,
    UPDATE_VENDOR,
    GET_PROFILE,
    GET_VENDOR_PROFILE,
    UPDATE_USER_MODEL,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    SET_NEW_PASSWORD,
    CREATE_VENDOR,
    GET_VENDOR,
    SEND_SMS,
    SEND_SMS_USER,
    VERIFY_PHONE_NUMBER,
    VERIFY_PHONE_NUMBER_USER,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_APPEND,
    GET_NOTIFICATIONS_COUNT,
    UPDATE_USER_NOTIFICATIONS_COUNT,
    UPDATE_NOTIFICATIONS_COUNT,
    REGISTER_DEVICE,
    MARK_AS_READ,
    MARK_AS_READ_ALL,
    MARK_AS_READ_ALL_VENDORNOTES,
    GET_DASHBOARD_TOTALS,
    GET_ACCEPTED_ORDERS,
    GET_NUMBER_OF_QUOTES,
    INVITE_TEAM_MEMBER,
    GET_TEAM_MEMBERS,
    GET_TEAM_PROFILE,
    GET_SEARCH_ANALYTICS,
    CARDS_DATA
} = REQUEST_TYPE;
const {
    SAVE_USER_DETAIL_LOCAL,
    SAVE_NOTIFICATION,
    SAVE_USER_INFO,
    SAVE_USER_CACHE,
    SAVE_BRANDING,
    SAVE_ORDER_BRAND,
    SAVE_ORDER
} = NORMAL_TYPE;

const Actions = createrAction({
    actionName: 'UserAction',
    actionListNormal: [SAVE_USER_DETAIL_LOCAL, SAVE_NOTIFICATION, SAVE_USER_INFO, SAVE_USER_CACHE],
    actionListApi: [
        WHITE_LABEL,
        LOGIN,
        LOG_OUT,
        SAVE_BRANDING,
        SAVE_ORDER,
        SAVE_ORDER_BRAND,
        REGISTER,
        UPDATE_FINANCIAL,
        UPDATE_PROFILE,
        GET_PROFILE,
        GET_VENDOR_PROFILE,
        UPDATE_USER_MODEL,
        FORGOT_PASSWORD,
        CHANGE_PASSWORD,
        SET_NEW_PASSWORD,
        CREATE_VENDOR,
        GET_VENDOR,
        UPDATE_VENDOR,
        SEND_SMS,
        SEND_SMS_USER,
        VERIFY_PHONE_NUMBER,
        VERIFY_PHONE_NUMBER_USER,
        GET_NOTIFICATIONS,
        GET_NOTIFICATIONS_APPEND,
        GET_NOTIFICATIONS_COUNT,
        UPDATE_USER_NOTIFICATIONS_COUNT,
        UPDATE_NOTIFICATIONS_COUNT,
        REGISTER_DEVICE,
        MARK_AS_READ,
        MARK_AS_READ_ALL,
        MARK_AS_READ_ALL_VENDORNOTES,
        GET_DASHBOARD_TOTALS,
        GET_ACCEPTED_ORDERS,
        GET_NUMBER_OF_QUOTES,
        INVITE_TEAM_MEMBER,
        GET_TEAM_MEMBERS,
        GET_TEAM_PROFILE,
        GET_SEARCH_ANALYTICS,
        CARDS_DATA
    ]
});
myLog('Actions=>', Actions);

const whiteLabel = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: WHITE_LABEL,
        promiseApi: () => APIManager.getInstance().whiteLabel(params)
    });
};

const login = (params = {}) => {

    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: LOGIN,
        promiseApi: () => APIManager.getInstance().login(params)
    });
};
const logoutAction = () => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: LOG_OUT,
        promiseApi: () => {}
    });
}
const forgotPassword = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: FORGOT_PASSWORD,
        promiseApi: () => APIManager.getInstance().forgotPassword(params)
    });
};

const changePassword = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: CHANGE_PASSWORD,
        promiseApi: () => APIManager.getInstance().changePassword(params)
    });
};

const setNewPassword = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SET_NEW_PASSWORD,
        promiseApi: () => APIManager.getInstance().setNewPassword(params)
    });
};

const resetPassword = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: CHANGE_PASSWORD,
        promiseApi: () => APIManager.getInstance().resetPassword(params)
    });
};

const register = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: REGISTER,
        promiseApi: () => APIManager.getInstance().register(params)
    });
};
const createVendor = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: CREATE_VENDOR,
        promiseApi: () => APIManager.getInstance().createVendor(params)
    });
};
const updateProfile = (params = {}, id, isFormData = false) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: UPDATE_PROFILE,
        promiseApi: () =>
            isFormData
                ? APIManager.getInstance().updateProfileImage(params, id)
                : APIManager.getInstance().updateProfile(params, id)
    });
};
const updateVendor = (params = {}, id) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: UPDATE_VENDOR,
        promiseApi: () => APIManager.getInstance().updateVendor(params, id)
    });
};
const updateUserModel = (params = {}) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: UPDATE_USER_MODEL,
        promiseApi: () => APIManager.getInstance().updateUserModel(params)
    });
};
const updateFinancialInfo = (params = {}, id) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: UPDATE_FINANCIAL,
        promiseApi: () => APIManager.getInstance().updateFinancialInfo(params, id)
    });
};
const getUserProfile = (id) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_PROFILE,
        promiseApi: () => APIManager.getInstance().getUserProfile(id)
    });
};
const getVendorProfile = (id) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_VENDOR_PROFILE,
        promiseApi: () => APIManager.getInstance().getVendorProfile(id)
    });
};
const getVendor = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_VENDOR,
        promiseApi: () => APIManager.getInstance().getVendor(query)
    });
};
const saveUserDetailLocal = (data) => {
    return getAction({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_USER_DETAIL_LOCAL,
        data
    });
};
const saveUserInfo = (data) => {
    return getAction({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_USER_INFO,
        data
    });
};
const saveUserCache = (data) => {
    return getAction({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_USER_CACHE,
        data
    });
};
const saveUserNotification = (data) => {
    return getAction({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_NOTIFICATION,
        data
    });
};
const sendSMS = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SEND_SMS,
        promiseApi: () => APIManager.getInstance().sendSMS(query)
    });
};
const sendSMSUser = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SEND_SMS_USER,
        promiseApi: () => APIManager.getInstance().sendSMSUser(query)
    });
};

const verifyPhoneNumber = (params) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: VERIFY_PHONE_NUMBER,
        promiseApi: () => APIManager.getInstance().verifyPhoneNumber(params)
    });
};

const verifyPhoneNumberUser = (params) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: VERIFY_PHONE_NUMBER_USER,
        promiseApi: () => APIManager.getInstance().verifyPhoneNumberUser(params)
    });
};

const getNotifications = (params, append = false) => {
    const queryParams = params ? params : {
        page: 1,
        page_size: Utils.constants.pageLimit,
        vendor_notes:false
    }
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: append ? GET_NOTIFICATIONS_APPEND : GET_NOTIFICATIONS,
        promiseApi: () => APIManager.getInstance().getNotificationsApi(queryParams)
    });
};

const updateUserNotificationCount = (data) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: UPDATE_USER_NOTIFICATIONS_COUNT,
        promiseApi: () => data
    });
}

const getUnreadNotifCount = () => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_NOTIFICATIONS_COUNT,
        promiseApi: () => APIManager.getInstance().getUnreadNotifCountApi()
    });
};

const updateUnreadNotifCount = (data) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: UPDATE_NOTIFICATIONS_COUNT,
        promiseApi: () => data
    });
}

const registerDevice = (params) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: REGISTER_DEVICE,
        promiseApi: () => APIManager.getInstance().registerDevice(params)
    });
};
const markAsRead = (params) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: MARK_AS_READ,
        promiseApi: () => APIManager.getInstance().markAsRead(params)
    });
};

const markAsReadAll = (params) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: params.vendor_notes?MARK_AS_READ_ALL_VENDORNOTES:MARK_AS_READ_ALL,
        promiseApi: () => APIManager.getInstance().markAsReadAll(params)
    });
};

const getDashboardTotal = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_DASHBOARD_TOTALS,
        promiseApi: () => APIManager.getInstance().getDashboardTotals(query)
    });
};

const getAcceptedOrders = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_ACCEPTED_ORDERS,
        promiseApi: () => APIManager.getInstance().getAcceptedOrders(query)
    });
};

const getNumberOfQuotes = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_NUMBER_OF_QUOTES,
        promiseApi: () => APIManager.getInstance().getNumberOfQuotes(query)
    });
};

const inviteTeamMember = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: INVITE_TEAM_MEMBER,
        promiseApi: () => APIManager.getInstance().inviteTeamMember(query)
    });
};

const getTeamMembers = (query) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: INVITE_TEAM_MEMBER,
        promiseApi: () => APIManager.getInstance().getTeamMembers(query)
    });
};

const getTeamProfile = (id) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_TEAM_PROFILE,
        promiseApi: () => APIManager.getInstance().getTeamProfile(id)
    });
};

const saveBranding = () => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_BRANDING,
        promiseApi: () => APIManager.getInstance().getWhitelabel()
        // data
    });
};

const saveOrderConfigs = (data) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_ORDER_BRAND,
        promiseApi: () => APIManager.getInstance().getDynamicAttributes()
        // data
    });
};

const saveOrderDetails = (data) => {
    //
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_ORDER,
        promiseApi: () => data
        // data
    });
};

const resetOrderDetails = () => {
    //
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: SAVE_ORDER,
        promiseApi: () => ({})
        // data
    });
};

const getSearchAnalytics = (param) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: GET_SEARCH_ANALYTICS,
        promiseApi: () => APIManager.getInstance().getSearchAnalyticsManager(param)
    });
};

const getAnalyticsCards = (param1, param2, param3) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: CARDS_DATA,
        promiseApi: () => APIManager.getInstance().getAnalyticsCardsManager(param1, param2, param3)
    });
};

const getAnalyticsCardsWithoutEndDate = (param1, param2) => {
    return getActionAPI({
        Actions: Actions,
        visibleSpin: true,
        actionName: CARDS_DATA,
        promiseApi: () => APIManager.getInstance().getAnalyticsCardsManagerWithoutEndDate(param1, param2)
    });
};


export default {
    whiteLabel,
    saveUserCache,
    login,
    saveUserDetailLocal,
    saveUserNotification,
    saveUserInfo,
    register,
    updateProfile,
    getUserProfile,
    getVendorProfile,
    updateFinancialInfo,
    updateUserModel,
    forgotPassword,
    changePassword,
    resetPassword,
    setNewPassword,
    createVendor,
    getVendor,
    updateVendor,
    sendSMS,
    sendSMSUser,
    verifyPhoneNumber,
    verifyPhoneNumberUser,
    getNotifications,
    getUnreadNotifCount,
    registerDevice,
    markAsRead,
    markAsReadAll,
    getDashboardTotal,
    getAcceptedOrders,
    getNumberOfQuotes,
    inviteTeamMember,
    getTeamMembers,
    getTeamProfile,
    saveBranding,
    saveOrderConfigs,
    saveOrderDetails,
    resetOrderDetails,
    getSearchAnalytics,
    getAnalyticsCards,
    getAnalyticsCardsWithoutEndDate,
    logoutAction,
    updateUnreadNotifCount,
    updateUserNotificationCount
};
