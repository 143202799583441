import LocalStorage from '../../../../defined/localStorage';
import { Utils } from '../../../../utils';
import { signin } from '../Slice/authSlice';

const isDT = window.location.pathname.includes('dealer-technician');
const endpointType = isDT ? 'dealerTechnicianEndpoints' : 'userMobileEndpoints';

export const login = (values, setSubmitting, navigate, dispatch, onError, callback) => {
    const { email, password } = values;
    const dataToSend = {
        username: email,
        password
    };
    Utils.api.postApiCall(
        Utils.EndPoint[endpointType].loginApi,
        dataToSend,
        (respData) => {
            const { data } = respData;
            Utils.showAlert(1, data.message || 'Login Success!!');
            callback(data);
            dispatch(signin({ data: data }));

            LocalStorage.setItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN, data.token);
            LocalStorage.setItem(LocalStorage.DEFINE_KEY.JWT_TOKEN, data.jwt_token);
            LocalStorage.setItem(LocalStorage.DEFINE_KEY.ROLE, 'User');

            navigate(Utils.EndPoint[endpointType].createOrder);
            setSubmitting(false);
        },
        (error) => {
            const { data } = error;
            console.error(data);
            onError(data);
            Utils.showAlert(2, data?.non_field_errors?.[0] || 'Invalid Credentials');
            setSubmitting(false);
        }
    );
};

export const signup = (values, setSubmitting, navigate) => {
    Utils.api.postApiCallForm(
    Utils.EndPoint[endpointType].signupApi,
    values,
    () => {
      Utils.showAlert(1, "Verification mail is sent to the registered email");
      navigate(Utils.EndPoint[endpointType].signin);
    },
    (error) => {
      const { data } = error
      Utils.showAlert(2, data?.email[0] || 'Some error');
      setSubmitting(false)
    }
  )
}

export const sendOTPAPI = (values, setSubmitting, navigate, dispatch) => {
    Utils.api.postApiCall(
        Utils.EndPoint[endpointType].getOTP,
        values,
        () => {
            Utils.showAlert(1, 'OTP has been sent to your Email ID');
        },
        () => {
            Utils.showAlert(2, 'Failed to send the OTP, try again later');
        }
    );
};

export const sendOTPInSignUp = (values, sunccessCallback) => {
    Utils.api.postApiCall(
        Utils.EndPoint[endpointType].sendOTPAPISignUp,
        values,
        () => {
            sunccessCallback()
            Utils.showAlert(1, 'OTP has been sent to your Mobile');
        },
        () => {
            Utils.showAlert(2, 'Failed to send the OTP, try again later');
        }
    );
}

export const verifyOTPAPI = (values, navigate) => {
    Utils.api.postApiCall(
        Utils.EndPoint[endpointType].validateOTP,
        values,
        (respData) => {
            const { data: { token } } = respData;
            Utils.showAlert(1, 'OTP has been verifed');
            navigate(Utils.EndPoint[endpointType].reset_password, { state: { token } });
        },
        (error) => {
            console.error('err', error);
            const { data } = error;
            Utils.showAlert(2, 'Entered OTP is wrong try again');
        }
    );
};

export const verifyOTPAPISignUp = (values, sunccessCallback) => {
    Utils.api.postApiCall(
        Utils.EndPoint[endpointType].verifyOTPAPISignUP,
        values,
        (respData) => {
            Utils.showAlert(1, 'OTP has been verifed');
            sunccessCallback()
        },
        (error) => {
            const { data } = error;
            Utils.showAlert(2, 'Entered OTP is wrong try again');
        }
    );
};

export const resetPassAPI = (values, navigate, forgotPass = false) => {
    return new Promise((resolve, reject) => {
        Utils.api.postApiCall(
            Utils.EndPoint[endpointType].resetPassAPI,
            values,
            () => {
                Utils.showAlert(1, 'Password has been changed');
                if (forgotPass) {
                    navigate(Utils.EndPoint[endpointType].signin);
                    LocalStorage.clear();
                } else {
                    navigate(Utils.EndPoint[endpointType].settings);
                }
                resolve();
            },
            (error) => {
                console.error('err', error);
                const { data } = error;
                Utils.showAlert(2, data);
                reject(data);
            }
        );
    });
};

export const deleteAccountAPI = (values, navigate) => {
    Utils.api.deleteApiCall(
        Utils.EndPoint[endpointType].deleteAPI + values,
        '',
        () => {
            Utils.showAlert(1, 'Account has been deleted');
            navigate(Utils.EndPoint[endpointType].signin);
        },
        (error) => {
            console.error('err', error);
            const { data } = error;
            Utils.showAlert(2, data);
        }
    );
};
